<template>
  <div class="section text-center">

    <div v-if="this.isOrderSuccess">
      <div class="successIcon"><font-awesome-icon icon="check-circle"/></div>
      <h1 class="topText">תודה על ההזמנה!</h1>
      <p class="lead secondText">מיד ישלח אליך מייל עם פרטי ההזמנה</p>
      <hr>
      <p class="thirdText">
        במידה ולא קיבלת מייל בדקות הקרובות, אנא צור איתנו קשר
      </p>
      <p class="lead pt-3">
        <a class="btn btn-success btn-m" href="/" role="button">לעמוד הבית</a>
      </p>
    </div>

    <div v-if="!this.isOrderSuccess" >
      <div class="failedIcon failed" ><font-awesome-icon icon="times-circle"/></div>
      <p class="lead failedText">אירעה שגיאה והזמנתך אינה נקלטה במערכת<br> נסה שנית</p>
      <hr>
       <p class="lead pt-3">
        <a class="btn btn-dark btn-m" href="/סל-קניות" role="button">לסל הקניות</a>
      </p>
    </div>

  </div>
</template>
<script>
import { mapState, mapActions} from 'vuex'

export default {
  computed: {
  ...mapState(['isOrderSuccess']),
     },
  methods: {
     ...mapActions(['setCart']),

   scrollToTop() {
        window.scrollTo(0,0);
   },
  },
  created() {
    this.scrollToTop();

    if(this.isOrderSuccess) 
      this.setCart([]);
  }
}

</script>

<style scoped>
.successIcon{
    padding-bottom: 1%;
    font-size: calc(1em + 3vw);
}
.failedIcon{
    padding-bottom: 1%;
    font-size: calc(2em + 2.8vw);
}
.failed{
    color: rgb(161, 20, 28);
}
.section {
    padding: 7rem 1rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
}
.topText {
    font-size: calc(1em + 3.5vw);
}
.secondText {
    font-size: calc(0.8em + 0.8vw);
}
.thirdText {
    font-size: calc(0.5em + 0.6vw);
}
.failedText {
    font-size: calc(0.9em + 1.5vw);
}
</style>
