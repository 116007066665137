<template>
  <div id="cart">

      <div class="container">
<div class="shopping-cart">
  <!-- Title -->
  <div class="title">
    עגלת קניות
  </div>
 <div v-if="cart === 'undefined' || cart.length === 0" class="empty">העגלה עדיין ריקה</div>

  <!-- Product #1 -->
  <div v-for="(item, index) in cart" :key="index" class="item">
    
 
    <div class="image imageMargin">
      <img :src="item.image" :alt="item.name"  class="item-thumbnail"/>
    </div>
 
    <div class="description">
      <span>{{ item.name }}</span>
      <span>{{ getCutSize(item.size) }}</span>
      <span>{{ item.color }}</span>
    </div>
 
    <div class="quantity">
      <button class="plus-btn btn1" type="button" name="button" @click="increment(index)">
        <font-awesome-icon icon="plus"/>
      </button>
      <input type="text" name="name" v-model="item.quantity" readonly>
      <button class="minus-btn btn1" type="button" name="button" @click="decrement(index)">
        <font-awesome-icon icon="minus"/>
      </button>
    </div>
 
    <div class="total-price">{{ item.price }} ש"ח</div>

    <div class="buttons">
      <font-awesome-icon icon="trash" class="delete-btn" @click="deleteFromCart(index)"/>
    </div>
  </div>
 
 <div class="summary">

  <div class="d-flex flex-column mt-2">
    <label for="coupon" class="mylabel ml-auto">קוד קופון:</label>
    <div class="parent">
      <div class="customWidth">
        <input type="text" v-model="couponCode" id="coupon" name="coupon" 
        class="form-control myinput" :class="{ 'is-invalid': isCouponWork === false, 
        'is-valid': isCouponWork}"/>
        <div v-if="isCouponWork === false" class="invalid-feedback">קוד קופון לא תקין!</div>
        <div v-if="isCouponWork" class="valid-feedback">קופון הוזן בהצלחה!</div>
      </div>
      <b-button pill class="btn-dark mr-auto couponBtn hvr-pop" 
      @click="submitCoupon">הוסף קופון</b-button>
    </div>
  </div>

<div class="shippings mt-4"><b>אפשרויות משלוח: </b></div>


  <div class="shippings mt-2">
    <label><input class="ml-1 radiobtn" type="radio" name="optradio" v-model="shippingPrice" checked
    value="0">איסוף עצמי מרמת גן</label>
  </div>

  <div class="shippings" v-if="!cartContainsRod && !cartContainsCarpet">
    <label><input class="ml-1 radiobtn" type="radio" name="optradio" v-model="shippingPrice" 
    value="25" >משלוח באמצעות Boxit לנקודת איסוף קרובה (עד 10 ימי עסקים) - 25 ש"ח</label>
  </div>



  <div class="shippings">
    <label><input class="ml-1 radiobtn" type="radio" name="optradio" v-model="shippingPrice" 
    value="50" >משלוח עם שליח עד הבית (עד 10 ימי עסקים) - 50 ש"ח</label>
  </div>


  <h3 class="cart-line">
  סכום
  <span class="cart-price">{{ getSubtotal }} ש"ח</span>
</h3>
<h3  v-if="this.coupon!=0" class="cart-line couponLine">
  קופון הנחה
  <span class="couponLine">{{ this.coupon }}%-</span>
</h3>
<h3 class="cart-line">
  משלוח
  <span class="cart-price">{{ this.shippingPrice }} ש"ח</span>
</h3>
<h3 class="cart-line pb-4">
  סך הכל
  <span class="cart-price cart-total">{{ getTotal }} ש"ח</span>
</h3>
 </div>
<button type="button" :disabled="cart === 'undefined' || cart.length === 0 " class="submitbtn" 
@click="goToFormPage()">המשך לביצוע הזמנה</button>

    </div>
</div>
<div v-if="this.showSpinner" class="loader"></div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import promos from '@/values'
export default {
    data() {
        return {
        showSpinner: false,
        shippingPrice: "0",
        quantity:1,
        couponCode : "",
        isCouponWork: undefined
        }
    },
    computed: {
  ...mapState(['cart', 'coupon', 'products']),
     getSubtotal() {
      return this.cart.reduce(
        (a, b) => a + b.price * b.quantity,
        0
      )
    },
    getTotal() {
      if(this.coupon !== 0) {
        return (
          (this.getSubtotal * (100 - this.coupon) / 100) + parseInt(this.shippingPrice) 
        )
      } else {
        return (
          this.getSubtotal + parseInt(this.shippingPrice) 
        )
      }
    },
    getShortSize(size) {
      return (
        size.toString().slice(0, size.toString().indexOf("-"))
      )
    },

    cartContainsRod() {
      let isRod = false;
      for(let i=0; i < this.cart.length; i++) {
        if(this.isProductByString(this.products.find(element => element.name === this.cart[i].name ), "rod")) 
          isRod = true;
      }
      return (
        isRod
      )
    },

    cartContainsCarpet() {
      let isCarpet = false;
      for(let i=0; i < this.cart.length; i++) {
        if(this.isProductByString(this.products.find(element => element.name === this.cart[i].name ), "carpet")) 
          isCarpet = true;
      }
      return (
        isCarpet
      )
    }
  
    },
     methods: {
    ...mapActions(['setCart', 'deleteFromCart', 'changeQuantity', 'changeShippingPrice', 'changeCoupon']
    ),
    isProductByString(product, type){
        return this.getToLowerCaseArray(product.properties).includes(type);
    },
    getToLowerCaseArray(array) {
      var arr = array.map(v => v.toString().toLowerCase());
      return arr;
    },
    getCutSize(str) {
      let size = str + "";
      let index = size.indexOf("-");
      if( index !== -1) 
        return size.slice(0, index);
      else
        return str;
    },

    increment (index) {
       if(this.cart[index].quantity < 10) {
         this.cart[index].quantity++;
         this.changeQuantity({'index': index, 'newQuantity': this.cart[index].quantity});
       }
    },
    decrement (index) {
      if(this.cart[index].quantity != 1) {
        this.cart[index].quantity--;
        this.changeQuantity({'index': index, 'newQuantity': this.cart[index].quantity});
      }
    },
    goToFormPage() {
      this.changeShippingPrice(parseInt(this.shippingPrice));
      this.setCart(this.cart);
      this.$router.push('/פרטים').catch(err => {err})
    },
    submitCoupon() {
      this.isCouponWork = undefined;
      this.showSpinner = true;

      setTimeout(() => {
        for (let i=0; i<promos.length; i++) {
          if(this.couponCode === promos[i].code) {
            this.isCouponWork = true;
            this.changeCoupon(promos[i].value);
          }
        }
        if(this.isCouponWork !== true) {
          this.isCouponWork = false;
          this.changeCoupon(0);
        }
        this.showSpinner = false;
      }, 1000);
    },
    scrollToTop() {
        window.scrollTo(0,0);
    },
    },
    created() {
    this.scrollToTop();
  }
}

</script>

<style scoped>
#cart {
  background-color: #c29a71bd;
  padding-top: 5px;
  padding-bottom: 10px;
}
.container {
    height: 100%
}
* {
  box-sizing: border-box;
}
.shopping-cart {
  width: 600px;
  height: 100%;
  margin: 80px auto;
  background: #FFFFFF;
  box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.10);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.title {
  height: 60px;
  border-bottom: 1px solid #E1E8EE;
  padding: 20px 30px;
  color: #5E6977;
  font-size: 18px;
  font-weight: 400;
}
 
.item {
  padding: 20px 20px;
  height: 120px;
  display: flex;
  border-bottom: 2px solid #E1E8EE;
}
 
.buttons {
  position: relative;
  padding-top: 30px;
  margin-right: 20px;
}
.delete-btn {
  display: inline-block;
  Cursor: pointer;
}
.delete-btn {
  width: 18px;
  height: 17px;
  background: url(&quot;delete-icn.svg&quot;) no-repeat center;
}
 
.image {
  margin-right: -30px;
}
 
.description {
  padding-top: 10px;
  margin-right: 60px;
  width: 115px;
}
 
.description span {
  display: block;
  font-size: 13px;
  color: #43484D;
  font-weight: 400;
}
 
.description span:first-child {
  margin-bottom: 5px;
}
.description span:last-child {
  font-weight: 300;
  margin-top: 8px;
  color: #86939E;
}
.quantity {
  padding-top: 20px;
  margin-right: 60px;
}
.quantity input {
  -webkit-appearance: none;
  border: none;
  text-align: center;
  width: 32px;
  font-size: 16px;
  color: #43484D;
  font-weight: 300;
}
 
.btn1 {
  background-color: #E1E8EE;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}
.minus-btn img {
  margin-bottom: 3px;
}
.plus-btn img {
  margin-top: 2px;
}
 
button:focus,
input:focus {
  outline:0;
}
.total-price {
  width: 83px;
  padding-top: 27px;
  text-align: center;
  font-size: 16px;
  color: #43484D;
  font-weight: 300;
}
@media (max-width: 800px) {
  .shopping-cart {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .item {
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
  }
  .image img {
    width: 50%;
    
  }
  .image,
  .quantity,
  .description {
    width: 100%;
    text-align: center;
    margin: 6px 0;
  }
  .buttons {
    margin-right: 20px;
  }
}
.item-thumbnail {
  width: 60px;
  border-radius: 3px;
}
.cart-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0 0;
  font-size: inherit;
  font-weight: normal;
  color: rgba(51, 58, 69, 0.8);
}
.cart-price {
  color: #333a45;
}
.cart-total {
  font-size: 130%;
}
.summary {
    padding-right: 30px;
    padding-left: 30px;
}
.submitbtn{
  border-radius: 8px;
  background-color: #0b0b0be0;
  border: none;
  color: white;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
}
.empty {
  margin-top: 50px;
  margin-bottom: 50px;
}
.shippings {
  text-align: right;
}

@media (max-width: 800px) {
.imageMargin{
  margin-right: 0px;
}
}

@media (min-width: 800px) {
.imageMargin{
  margin-right: 5px;
}
}
.myinput {
  border-radius: 20px;
  width: 100%;
}
.mylabel {
  font-weight: bold;
  margin-bottom: 0rem;
  padding: 0.5rem 0rem 0.5rem 0.5rem !important;
}
/* Pop */
@-webkit-keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.hvr-pop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pop:active {
  -webkit-animation-name: hvr-pop;
  animation-name: hvr-pop;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
@media (min-width: 330px) {
.customWidth {
  width:50%
}
}
@media (max-width: 330px) {
  .couponBtn {
  height:100%;
  padding: 5px 20px 5px 20px;
  margin-top: 8px;
}
}
.couponBtn {
  height:100%;
  padding: 5px 20px 5px 20px;
}
.couponLine {
  color: #00a123;
  font-weight: bold;
}
@media (min-width: 330px) {
.parent {
  display: flex !important;
}
}
.loader {
  color: #1f201f;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; 
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  30% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  58% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
 .radiobtn {
    transform: translateY(17%);
 }

@media only screen and (max-width: 700px) {
  .radiobtn {
    transform: translateY(20%);
  }
}
</style>
