<template>
  <div class="section text-center">
    <div class="successIcon"><font-awesome-icon icon="check-circle"/></div>
    <h1 class="topText">כמעט סיימנו :)</h1>
    <p class="lead secondText">כעת נשאר להעביר תשלום דרך אפליקציית ביט</p>
    <hr>
    <p class="secondText">
      מיד ישלח אליך מייל עם כל הפרטים
    </p>
    <p class="lead pt-3">
      <a class="btn btn-success btn-m pb-2" href="/" role="button">לעמוד הבית</a>
    </p>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
      computed: {
        ...mapState(['cart', 'shippingPrice', 'transactionID']),
          getSubtotal() {
            return this.cart.reduce(
              (a, b) => a + b.price * b.quantity,
              0
            )
          },
          getTotal() {
            return (
              this.getSubtotal + this.shippingPrice
            )
           }
           },
      methods: {
        scrollToTop() {
              window.scrollTo(0,0);
        },
  },
  created() {
    this.scrollToTop();
  }
}
</script>

<style scoped>
.section {
    padding: 7rem 1rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
}
.topText {
  font-size: calc(1em + 3vw);
}
.secondText {
  margin-top: 2%;
  font-size: calc(0.8em + 0.8vw);
}
.thirdText {
  margin-top: 1%;
  font-size: calc(0.5em + 0.6vw);
}
.successIcon{
    padding-bottom: 1%;
    font-size: calc(1em + 3vw);
}
</style>
