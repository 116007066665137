const long = 'ארוך - רוחב 140 / אורך 250 ס"מ ליחידה, 2 יחידות באריזה';
const short = 'קצר - רוחב 140 / אורך 160 ס"מ ליחידה, 2 יחידות באריזה';
const pillow_fill_standard = '45 / 45 ס"מ - כולל מילוי';
const pillow_noFill_standard = '45 / 45 ס"מ - ציפית בלבד';
const pillow_fill_extra = '60 / 60 ס"מ - כולל מילוי';
const pillow_noFill_extra = '60 / 60 ס"מ - ציפית בלבד';
const extraLong = 'אקסטרה ארוך - רוחב 140 / אורך 270 ס"מ ליחידה, 2 יחידות באריזה';
const extraLong280 = 'אקסטרה ארוך - רוחב 140 / אורך 280 ס"מ ליחידה, 2 יחידות באריזה';
const set = 'סט - רוחב 140 / אורך 250 ס"מ ליחידה, 4 יחידות באריזה';
const koteret = 'כותרת - רוחב 140 / אורך 60 ס"מ ליחידה, יחידה אחת באריזה';
const extraWide = 'אקסטרה רחב - רוחב 280 / אורך 250 ס"מ ליחידה, יחידה אחת באריזה';
const paldim_450 = ' 4.5 מטר בד - רוחב: כיסוי של 1.5 מטר / אורך 260 ס"מ , יחידה אחת באריזה';
const paldim_600 = ' 6 מטר בד - רוחב: כיסוי של 2 מטר / אורך 260 ס"מ , יחידה אחת באריזה';

export const products_const = [

{ name: 'וילון קרפ השחלה' ,
title:'וילון בד נשפך חצי שקוף מוט מוסתר', 
price_to_show: 120,
pricesAndSizes: [ 
                {'desc': long,
                'price': 140,
                'no_colors': []},

                {'desc': short,
                'price': 120,
                'no_colors': []},

                {'desc': extraWide,
                'price': 150,
                'no_colors': []},

                {'desc': 'אורך מיוחד - רוחב 280 / אורך 280 ס"מ ליחידה, יחידה אחת באריזה',
                'price': 160,
                'no_colors': []},
],
colors: ['לבן'],
images: [require("./assets/crepe_back(1).jpg"),
        require("./assets/1aOiHXHK.jpg"),
        require("./assets/crepe_back.jpg"),
        require("./assets/crepe_back4.jpg"),
        require("./assets/crepe_back2.jpg"),
        require("./assets/crepe_back5.jpg"),
        require("./assets/crepe_back(2).jpg")],
properties: ["curtain", "short", "long", "extra wide"],
},

  { name: 'וילון הילה' , 
  title:' שקוף בצורה גאומטרית', 
  price_to_show: 120,
  pricesAndSizes: [ 
                  {'desc': long,
                  'price': 160,
                  'no_colors': []},

                  {'desc': short,
                  'price': 120,
                  'no_colors': []},

                  {'desc': koteret,
                  'price': 50,
                  'no_colors': []},

                  {'desc': extraWide,
                  'price': 160,
                  'no_colors': []},
],
  colors: ['לבן' ,'אופוויט'],
  images: [require("./assets/hila.jpg"),
           require("./assets/Rs5H58l.jpg"),],
  properties: ["curtain", "short", "long", "koteret", "extra wide"],
 }, 

 { name: 'וילון קרפ טבעות' , 
  title:'בד נשפך חצי שקוף',
  price_to_show: 120,
  pricesAndSizes: [ 
                  {'desc': long,
                  'price': 140,
                  'no_colors': []},

                  {'desc': short,
                  'price': 120,
                  'no_colors': []},

                  {'desc': extraLong,
                  'price': 160,
                  'no_colors': ['אפור']},
],
  colors: ['לבן' ,'אופוויט'],
  images: [require("./assets/crepe_rings1.jpg"),
           require("./assets/crepe_rings2.jpg"),
           require("./assets/hTWQgUp.jpg"),
           require("./assets/FZQVW4F.jpg"),
           require("./assets/MWFdFjx.jpg"),],
  properties: ["curtain", "short", "long", "extra long"],
 }, 

{ name: 'וילון חושן' , 
title:'חצי אטום בצבעים', 
price_to_show: 110,
pricesAndSizes: [ 
                // {'desc': short,
                // 'price': 110,
                // 'no_colors': ['מוקה']},

                {'desc': long,
                'price': 140,
                'no_colors': ['מוקה','תכלת-טורקיז','ירוק']},
],
colors: ['לבן','מוקה','תכלת-טורקיז','ירוק'],
images: [require("./assets/hoshen1.jpg"),
         require("./assets/z7HR6Ob.jpg"),
         require("./assets/f4oERVV.jpg"),
         require("./assets/gcba72F.jpg")],
// properties: ["curtain", "short", "long"],
properties: ["curtain", "long"],
},
 

//  { name: 'וילון שוהם' , 
//  title:'וילון שקוף בעיטור עיגולים', 
//  price_to_show: 120,
//  pricesAndSizes: [ 

//                 {'desc': short,
//                  'price': 120,
//                  'no_colors': []},

//                  {'desc': long,
//                  'price': 150,
//                  'no_colors': []},
//  ],
//  colors: ['אפור'],
//  images: [require("./assets/shoam.jpg"),],
//  properties: ["curtain", "short", "long"],
//  },

 { name: "וילון פיג'י" , 
  title:'חצי שקוף דמוי פישתן טבעי',
  price_to_show: 120,
  pricesAndSizes: [ 
                  {'desc': long,
                  'price': 170,
                  'no_colors': []},

                  {'desc': short,
                  'price': 120,
                  'no_colors': []},
],
  colors: ['אופוויט','אבן','אפור','חום'],
  images: [ require("./assets/piji3.jpg"),
            require("./assets/piji4.jpg"),
            require("./assets/piji1.jpg"),
           require("./assets/piji2.jpg"),
           require("./assets/RS9Hv1d.jpg"),
           require("./assets/fqaSzYW.jpg"),
           require("./assets/piji5.jpg"),
           require("./assets/Sx58hd6.jpg"),
           require("./assets/pigi2.jpg"),
           require("./assets/pigi4.jpg"),],
  properties: ["curtain", "short", "long"],
 },



//  { name: 'וילון פריז' , 
//  title:'תחרה שקוף', 
//  price_to_show: 140,
//  pricesAndSizes: [ 
//                  {'desc': long,
//                  'price': 140,
//                  'no_colors': []},
//  ],
//  colors: ['אופוויט'],
//  images: [require("./assets/paris1.jpg"),
//           require("./assets/paris2.jpg")],
//  properties: ["curtain", "long"],
//  description: "וילון תחרה שקוף בעיטור פרחים למראה רומנטי"
//  },

 { name: 'וילון שיר דאבל' , 
title:' שתי שכבות, אטום סאטן ושקוף מעל ', 
price_to_show: 120,
pricesAndSizes: [ 
                {'desc': long,
                'price': 160,
                'no_colors': ["לבן"]},

                {'desc': short,
                'price': 120,
                'no_colors': []},

                // {'desc': extraWide,
                // 'price': 160,
                // 'no_colors':  ['אפור']},
],
colors: ['מוקה','אפור','לבן'],
images: [require("./assets/shir_double1.jpg"),
         require("./assets/shir_double2.jpg"),
         require("./assets/shir_double3.jpg"),
         require("./assets/shir_double4.jpg"),
         require("./assets/57WMyES.jpg"),
         ],
properties: ["curtain", "long","short", "extra wide"],
},


//  { name: 'שטיח חבל שקד' , 
// price_to_show: 240,
// pricesAndSizes: [ 

//                    {'desc': '160/230 ס״מ',
//                    'price': 350,
//                    'no_colors': []},
// ],
// colors: ['טבעי-01'],
// images: [require("./assets/ashram_shaked.jpg"),
//          require("./assets/ashram_shaked2.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח חבל משולב פרנזים"
// },

// { name: 'שטיח חבל טהיטי' , 
// price_to_show: 100,
// pricesAndSizes: [ 

//                   {'desc': 'אובלי 60 / 120  ס"מ',
//                   'price': 100,
//                   'no_colors': []},

//                   {'desc': 'אובלי 60 / 180  ס"מ',
//                   'price': 150,
//                   'no_colors': []},

//                    {'desc': 'עגול 120 ס"מ',
//                    'price': 170,
//                    'no_colors': []},

//                    {'desc': 'עגול 150 ס"מ',
//                    'price': 270,
//                    'no_colors': []},

//                    {'desc': 'עגול 200 ס"מ',
//                    'price': 450,
//                    'no_colors': []},
// ],
// colors: ['טבעי'],
// images: [require("./assets/ashram_tahiti.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח חבל טבעי בשילוב זהב"
// },

// { name: 'ראנר עץ מיקונוס' , 
// price_to_show: 70,
// pricesAndSizes: [ 

//                   {'desc': 'ראנר 100 / 33 ס"מ',
//                   'price': 70,
//                   'no_colors': []},

//                   {'desc': 'ראנר 150 / 33 ס"מ',
//                   'price': 100,
//                   'no_colors': []},
// ],
// colors: ['טבעי'],
// images: [require("./assets/ashram_mikonos.jpg"),
//         ],
// properties: ["map","accessories","prices"],
// description: "מפת ראנר עץ בעבודת יד"
// },

// { name: 'ראנר עץ רודוס' , 
// price_to_show: 50,
// pricesAndSizes: [ 

//                   {'desc': 'ראנר 150 / 33 ס"מ',
//                   'price': 50,
//                   'no_colors': []},
// ],
// colors: ['אדום','טורקיז','חרדל'],
// images: [require("./assets/ashram_rhodes.jpg"),
//         ],
// properties: ["map","accessories","prices"],
// description: "מפת ראנר עץ בשילוב בד"
// },



 { name: 'וילון גלילה זברה' ,
 price_to_show: 180,
 pricesAndSizes: [ {'desc': '80 / 160 ס"מ',
                  'price': 180,
                  'no_colors': []},

                  {'desc': '100 / 160 ס"מ',
                  'price': 200,
                  'no_colors': []},

                  {'desc': '120 / 160 ס"מ',
                  'price': 220,
                  'no_colors': []},

                  {'desc': '140 / 160 ס"מ',
                  'price': 240,
                  'no_colors': []},

                  {'desc': '160 / 160 ס"מ',
                  'price': 260,
                  'no_colors': []},

                  {'desc': '180 / 160 ס"מ',
                  'price': 290,
                  'no_colors': []},

                  {'desc': '200 / 160 ס"מ',
                  'price': 330,
                  'no_colors': []},

                  {'desc': '220 / 160 ס"מ',
                  'price': 350,
                  'no_colors': []},

                  {'desc': '240 / 160 ס"מ',
                  'price': 380,
                  'no_colors': []},

                  {'desc': '80 / 260 ס"מ',
                  'price': 220,
                  'no_colors': []},

                  {'desc': '100 / 260 ס"מ',
                  'price': 270,
                  'no_colors': []},

                  {'desc': '120 / 260 ס"מ',
                  'price': 340,
                  'no_colors': []},

                  {'desc': '140 / 260 ס"מ',
                  'price': 370,
                  'no_colors': []},

                  {'desc': '160 / 260 ס"מ',
                  'price': 430,
                  'no_colors': []},

                  {'desc': '180 / 260 ס"מ',
                  'price': 450,
                  'no_colors': []},

                  {'desc': '200 / 260 ס"מ',
                  'price': 480,
                  'no_colors': []},

                  {'desc': '220 / 260 ס"מ',
                  'price': 520,
                  'no_colors': []},

                  {'desc': '240 / 260 ס"מ',
                  'price': 570,
                  'no_colors': []},
],
 colors: ['לבן' ,'אופוויט','אפור','אבן'],
 images: [require("./assets/zebra4.jpg"),
   require("./assets/r659U9h.jpg"),
          require("./assets/Qz4zHID.jpg"),
          require("./assets/fmKDcWf.jpg"),
          require("./assets/qsk901w.jpg"),
          require("./assets/zebra1.jpg"),
          require("./assets/zebra2.jpg"),],
             
 properties: ["curtain","zebra"],
 description: 'וילון גלילה במראה מודרני מסוג בד טורקי איכותי'
},


 


{ name: "וילון פינה פלדים" , 
title:'שקוף עם דוגמא', 
price_to_show: undefined,
pricesAndSizes: [ 
                {'desc': paldim_450,
                'price': 'להצעת מחיר חייגו',
                'no_colors': []},

                {'desc': paldim_600,
                'price': 'להצעת מחיר חייגו',
                'no_colors': []},
],
colors: ['לבן' ,'חום'],
images: [require("./assets/pina_paldim.jpg")],
properties: ["curtain", "long", "paldim","no_price"],
},


{ name: 'וילון מונטנה' , 
 price_to_show: 130,
 pricesAndSizes: [ 
                 {'desc': long,
                 'price': 130,
                 'no_colors': []},
 
                 {'desc': short,
                 'price': 100,
                 'no_colors': ['לבן']},
 ],
 colors: ['אפור', 'לבן'],
 images: [require("./assets/montana1.jpg"),
          require("./assets/montana2.jpg"),],
 properties: ["curtain", "short", "long"],
 description: "וילון דמוי פשתן במרקם עדין"

 },

//  { name: 'וילון אלון' , 
// title:'וילון שקוף בעיטור עלים', 
// price_to_show: 160,
// pricesAndSizes: [ 
//                 {'desc': long,
//                 'price': 160,
//                 'no_colors': []},
// ],
// colors: ['לבן'],
// images: [require("./assets/tamar3.jpg"),
//          require("./assets/tamar2.jpg"),],
// properties: ["curtain", "long"],
// },

// { name: 'וילון אטום 80% דורית סינגל' , 
// title:'בד דוגמת שיש', 
// price_to_show: 120,
//  pricesAndSizes: [ 
//                  {'desc': long,
//                  'price': 180,
//                  'no_colors': []},

//                  {'desc': short,
//                  'price': 120,
//                  'no_colors': []},
// ],
// colors: ['מוקה'],
// images: [require("./assets/dorit.jpg")],
// properties: ["curtain", "short", "long", "sunproof"],
// },  

{ name: 'וילון ריקי' , 
title:'וילון שקוף בעיטור פרחים', 
price_to_show: 170,
pricesAndSizes: [ 
                {'desc': extraWide,
                'price': 170,
                'no_colors': []},
],
colors: ['לבן ואפור'],
images: [require("./assets/riki1.jpg"),
        require("./assets/riki2.jpg"),
        ],
properties: ["curtain", "long", "extra wide"],
},

{ name: 'וילון אתי' , 
title:'וילון שקוף בעיטור פרחים', 
price_to_show: 170,
pricesAndSizes: [ 
                {'desc': extraWide,
                'price': 170,
                'no_colors': []},
],
colors: ['אופוויט ואפור'],
images: [require("./assets/eti1.jpg"),
        require("./assets/eti2.jpg"),
        ],
properties: ["curtain", "long", "extra wide"],
},

{ name: 'וילון פיקאסו השחלה' , 
title:'חצי שקוף דמוי פישתן מוט מוסתר', 
price_to_show: 120,
pricesAndSizes: [ 

                {'desc': short,
                'price': 120,
                'no_colors': []},

                {'desc': long,
                'price': 140,
                'no_colors': []},
],
colors: ['לבן', 'אופוויט'],
images: [require("./assets/picasso_back.jpg"),],
properties: ["curtain", "short", "long"],
},



// { name: 'הדום אליף' , 
// price_to_show: 320,
// pricesAndSizes: [ 

//                   {'desc': '45/45/45 ס"מ',
//                   'price': 320,
//                   'no_colors': []},
// ],
// colors: ['לבן', 'שחור'],
// images: [require("./assets/ashram_alif2.jpg"),
//          require("./assets/ashram_alif.jpg"),
//         ],
// properties: ["hadom","accessories","prices"],
// description: "הדום אליף 100% כותנה"
// },
 
// { name: 'הדום ורגאס' , 
// price_to_show: 230,
// pricesAndSizes: [ 

//                   {'desc': 'עגול 45/45/25 ס"מ',
//                   'price': 230,
//                   'no_colors': []},

//                   {'desc': 'עגול 45/35 ס"מ',
//                   'price': 240,
//                   'no_colors': []},

//                   {'desc': 'מרובע 49/49/25 ס"מ',
//                   'price': 230,
//                   'no_colors': []},
// ],
// colors: ['טבעי'],
// images: [require("./assets/ashram_vergas.jpg"),
//         ],
// properties: ["hadom","accessories","prices"],
// description: "הדום חבל ורגאס"
// },

// { name: 'כרית שיש' , 
// price_to_show: 30,
// pricesAndSizes: [ {'desc': pillow_fill_standard,
//                    'price': 30,
//                    'no_colors': []},

//                    {'desc': pillow_noFill_standard,
//                    'price': 20,
//                    'no_colors': []},

//                    {'desc': pillow_fill_extra,
//                    'price': 60,
//                    'no_colors': []},

//                    {'desc': pillow_noFill_extra,
//                    'price': 35,
//                    'no_colors': []},
// ],
// colors: ['שחור' , 'אפור' ,'חום' ,'מוקה' ],
// images: [require("./assets/pillow_stone4.jpg"),
//         require("./assets/pillow_stone.jpg"),
//         require("./assets/pillow_stone2.jpg"),
//         require("./assets/pillow_stone3.jpg"),
//         require("./assets/qbC0dqZ.jpg"),
//         ],
// properties: ["pillow","accessories","prices"],
// },

{ name: 'כרית פרווה' , 
price_to_show: 30,
pricesAndSizes: [ {'desc': pillow_fill_standard,
                   'price': 30,
                   'no_colors': []},

                   {'desc': pillow_noFill_standard,
                   'price': 20,
                   'no_colors': []},

                   {'desc': pillow_fill_extra,
                   'price': 60,
                   'no_colors': []},

                   {'desc': pillow_noFill_extra,
                   'price': 35,
                   'no_colors': []},
],
colors: ['שחור' , 'אפור' ,'לבן' ,'בז' ,'אדום'],
images: [require("./assets/pillow_fur1.jpg"),
         require("./assets/G1lQS4d.jpg"),
         require("./assets/orGmo5D.jpg"),
         require("./assets/miHlJcB.jpg"),
         
        ],
properties: ["pillow","accessories","prices"]
},




{ name: 'וילון פינה' , 
  title:'שקוף עם דוגמא', 
  price_to_show: 120,
  pricesAndSizes: [ 
                  {'desc': long,
                  'price': 160,
                  'no_colors': []},

                  {'desc': short,
                  'price': 120,
                  'no_colors': []},

                  {'desc': extraLong,
                  'price': 180,
                  'no_colors': ['אופוויט','אפור','שחור']},
],
  colors: ['לבן' ,'אופוויט','אפור','שחור'],
  images: [require("./assets/X8S3SSw.jpg"),
           require("./assets/pina.jpg"),
           require("./assets/WOTy4Yt.jpg"),
           require("./assets/G6ELMVp.jpg"),
           require("./assets/OPW4Nh0.jpg"),
           require("./assets/pina2.jpg"),
           require("./assets/pina3.jpg"),],
  properties: ["curtain", "short", "long", "extra long"],
 },


{ name: 'וילון קרפ פלדים' , 
 title:'בד נשפך חצי שקוף', 
 price_to_show: undefined,
 pricesAndSizes: [ 
                 {'desc': paldim_450,
                 'price': 'להצעת מחיר חייגו',
                 'no_colors': []},

                 {'desc': paldim_600,
                 'price': 'להצעת מחיר חייגו',
                 'no_colors': []},
 ],
 colors: ['לבן-מלוכלך'],
 images: [require("./assets/crepe_paldim1.jpg")],
 properties: ["curtain", "long", "paldim","no_price"],
 },
 
{ name: 'וילון וואל שקוף חלק' , 
 title:'שקוף בצבעים', 
 price_to_show: 60,
 pricesAndSizes: [ 
                {'desc': short,
                'price': 60,
                'no_colors': []},

                {'desc': long,
                'price': 110,
                'no_colors': []}
],
colors: ['אופוויט','לבן'],
images: [require("./assets/vual.jpg"),
         require("./assets/HNtIERP.jpg"),
         require("./assets/IodWW5M.jpg"),
         require("./assets/7KyTKp0.jpg")],
 properties: ["curtain", "short", "long", "cheap"],
 },

 { name: 'וילון פיקאסו' , 
 title:'חצי שקוף דמוי פישתן', 
 price_to_show: 90,
 pricesAndSizes: [ 
                 {'desc': long,
                 'price': 120,
                 'no_colors': []},

                 {'desc': short,
                 'price': 90,
                 'no_colors': []},

                 {'desc': extraWide,
                 'price': 120,
                 'no_colors': ['אפור','שחור','אדום']},
],
 colors: ['אופוויט','אפור','שחור' ,'לבן','אבן','אדום'],
 images: [require("./assets/picasso3.jpg"),
          require("./assets/picasso1.jpg"),
          require("./assets/iEi8Pvd.jpg"),
          require("./assets/OfE4wq6.jpg"),],
 properties: ["curtain", "short", "long", "extra wide", "cheap"],
},

 { name: 'וילון רומא' , 
  title:' חצי שקוף', 
  price_to_show: 120,
  pricesAndSizes: [ 
                  {'desc': long,
                  'price': 160,
                  'no_colors': []},

                  {'desc': short,
                  'price': 120,
                  'no_colors': []},

                  {'desc': extraWide,
                  'price': 160,
                  'no_colors': []},
],
  colors: ['לבן'],
  images: [require("./assets/vXkYfvs.jpg"),
           require("./assets/yeVYXcV.jpg"),
           require("./assets/D78ZEXJ.jpg"),
           require("./assets/gZMuiSx.jpg"),],
  properties: ["curtain","short", "long", "extra wide"],
 },

 { name: 'וילון פיקאסו פלדים' , 
 title:'חצי שקוף דמוי פישתן', 
 price_to_show: undefined,
 pricesAndSizes: [ 
                 {'desc': paldim_450,
                 'price': 'להצעת מחיר חייגו',
                 'no_colors': []},

                 {'desc': paldim_600,
                 'price': 'להצעת מחיר חייגו',
                 'no_colors': []},
 ],
 colors: ['לבן' ,'אופוויט','אבן'],
 images: [require("./assets/picasso_paldim.jpg")],
 properties: ["curtain", "long", "paldim","no_price"],
 },

{ name: 'וילון אלון' , 
title:'בד במראה אלגנטי עם דוגמא',
price_to_show: 120,
pricesAndSizes: [ 
                {'desc': long,
                'price': 170,
                'no_colors': []},

                {'desc': short,
                'price': 120,
                'no_colors': []}
],
colors: ['לבן'],
images: [require("./assets/optJlnS.jpg"),
         require("./assets/H0oYHGB.jpg"),
         require("./assets/9OD8HJ2.jpg")],
properties: ["curtain", "long","short"],
}, 

 { name: 'וילון אטום בלאק אאוט' , 
  title:'אטום 80 אחוז', 
  price_to_show: 120,
  pricesAndSizes: [ 
                  {'desc': long,
                  'price': 170,
                  'no_colors': []},

                  {'desc': short,
                  'price': 120,
                  'no_colors': []},
],
  colors: ['לבן' ,'אופוויט','אבן','אפור','שחור'],
  images: [require("./assets/aRt5TL4.jpg"),
           require("./assets/d4Lj7eW.jpg"),
           require("./assets/eqJ8kuw.jpg"),],
  properties: ["curtain", "short", "long", "sunproof"],
}, 

// { name: "שטיח חבל אנג'לו" , 
// price_to_show: 100,
// pricesAndSizes: [ 
//                   {'desc': '60 / 90 ס"מ',
//                    'price': 100,
//                    'no_colors': []},

//                   {'desc': '60 / 120 ס"מ',
//                    'price': 140,
//                    'no_colors': []},

//                    {'desc': '60 / 180 ס"מ',
//                    'price': 210,
//                    'no_colors': []},

//                    {'desc': '120 / 180 ס"מ',
//                    'price': 360,
//                    'no_colors': []},

//                   {'desc': '160 / 230 ס"מ',
//                    'price': 670,
//                    'no_colors': []},

//                    {'desc': '180 / 270 ס"מ',
//                    'price': 900,
//                    'no_colors': []},

//                    {'desc': '300 / 400 ס"מ',
//                    'price': 2000,
//                    'no_colors': []},
// ],
// colors: ['טבעי-01'],
// images: [require("./assets/ashram_angelo.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח חבל עבודת יד"
// },

// { name: "שטיח יהלום" , 
// price_to_show: 50,
// pricesAndSizes: [ 
//                   {'desc': '50 / 80 ס"מ',
//                    'price': 50,
//                    'no_colors': []},

//                   {'desc': '60 / 100 ס"מ',
//                    'price': 80,
//                    'no_colors': []},

//                    {'desc': '70 / 120 ס"מ',
//                    'price': 150,
//                    'no_colors': []},

//                    {'desc': '70 / 180 ס"מ',
//                    'price': 210,
//                    'no_colors': []},

//                   {'desc': '120 / 180 ס"מ',
//                    'price': 370,
//                    'no_colors': []},

//                    {'desc': '160 / 230 ס"מ',
//                    'price': 640,
//                    'no_colors': []},

//                    {'desc': '200 / 300 ס"מ',
//                    'price': 1000,
//                    'no_colors': []},
// ],
// colors: ['לבן'],
// images: [require("./assets/ashram_diamond.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח 100% כותנה"
// },

{ name: 'שטיח אסיף' , 
price_to_show: 180,
pricesAndSizes: [ 
                   {'desc': 'עגול 120 ס"מ',
                   'price': 280,
                   'no_colors': []},

                   {'desc': 'עגול 160 ס"מ',
                   'price': 400,
                   'no_colors': []},
  
                  {'desc': '80 / 130 ס"מ',
                   'price': 180,
                   'no_colors': []},

                   {'desc': '120 / 180 ס"מ',
                   'price': 350,
                   'no_colors': []},

                   {'desc': '160 / 230 ס"מ',
                   'price': 560,
                   'no_colors': []},
],
colors: ['בז','אפור','שמנת','שחור','ורוד-עתיק','קפה'],
images: [require("./assets/ashram_asif.jpg"),
        require("./assets/asif_fit.jpg"),
        require("./assets/ashram_asif2.jpg"),
        ],
properties: ["carpet","accessories","prices"],
description: "שטיח פרווה צפופה איכותית"
},

// { name: 'שטיח חבל אגוס' , 
// price_to_show: 50,
// pricesAndSizes: [ 

//                   {'desc': 'עגול 60 ס"מ',
//                    'price': 50,
//                    'no_colors': []},

//                   {'desc': 'עגול 100 ס"מ',
//                    'price': 150,
//                    'no_colors': []},

//                    {'desc': 'עגול 120 ס"מ',
//                    'price': 200,
//                    'no_colors': []},

//                    {'desc': 'עגול 150 ס"מ',
//                    'price': 310,
//                    'no_colors': []},

//                    {'desc': '120 / 180 ס"מ',
//                    'price': 420,
//                    'no_colors': []},

//                   {'desc': '160 / 230 ס"מ',
//                    'price': 700,
//                    'no_colors': []},

//                    {'desc': '200 / 300 ס"מ',
//                    'price': 1100,
//                    'no_colors': []},
// ],
// colors: ['אגוס-04','אגוס-03','אגוס-02', 'אגוס-01'],
// images: [require("./assets/ashram_agos2.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח חבל טבעי"
// },


{ name: 'כרית קטיפה' , 
price_to_show: 30,
pricesAndSizes: [ {'desc': pillow_fill_standard,
                   'price': 30,
                   'no_colors': []},

                   {'desc': pillow_noFill_standard,
                   'price': 20,
                   'no_colors': []},

                   {'desc': pillow_fill_extra,
                   'price': 60,
                   'no_colors': []},

                   {'desc': pillow_noFill_extra,
                   'price': 35,
                   'no_colors': []},
],
colors: ['אפור'],
images: [require("./assets/pillow_ktifa5.jpg"),
require("./assets/pillow_ktifa3.jpg"),
        require("./assets/pillow_ktifa.jpg"),
        require("./assets/pillow_ktifa2.jpg"),
        require("./assets/pillow_ktifa4.jpg"),
        
     ],
properties: ["pillow","accessories","prices"]
},

{ name: 'כרית קורדרוי' , 
price_to_show: 30,
pricesAndSizes: [ {'desc': pillow_fill_standard,
                   'price': 30,
                   'no_colors': []},

                   {'desc': pillow_noFill_standard,
                   'price': 20,
                   'no_colors': []},

                   {'desc': pillow_fill_extra,
                   'price': 60,
                   'no_colors': []},

                   {'desc': pillow_noFill_extra,
                   'price': 35,
                   'no_colors': []},
],
colors: ['אפור','בז'],
images: [require("./assets/pillow_corderoy4.jpg"),
        require("./assets/pillow_corderoy.jpg"),
         require("./assets/pillow_corderoy2.jpg"),
         require("./assets/pillow_corderoy3.jpg"),
         
         require("./assets/pillow_corderoy5.jpg"),
     ],
properties: ["pillow","accessories","prices"]
},

{ name: "מוט קריסטל" , 
price_to_show: 50,
pricesAndSizes: [ 
                   {'desc': '1.5 מטר',
                   'price': 50,
                   'no_colors': []},

                   {'desc': 'טלסקופי נפתח עד 3 מטר',
                   'price': 60,
                   'no_colors': []},

                   {'desc': 'טלסקופי נפתח עד 3.9 מטר',
                   'price': 80,
                   'no_colors': []},
],
colors: ['לבן', 'אפור'],
images: [require("./assets/rod_crystal.jpg"),
        ],
properties: ["rod","accessories","prices"],
},

{ name: "מוט כדור" , 
price_to_show: 30,
pricesAndSizes: [ 
                   {'desc': '1.5 מטר',
                   'price': 30,
                   'no_colors': []},

                   {'desc': 'טלסקופי נפתח עד 2.4 מטר',
                   'price': 45,
                   'no_colors': []},

                   {'desc': 'טלסקופי נפתח עד 3.9 מטר',
                   'price': 55,
                   'no_colors': []},
],
colors: ['לבן', 'אפור' , 'שחור'],
images: [require("./assets/rod_ball.jpg"),
        ],
properties: ["rod","accessories","prices"],
},

{ name: 'וילון אטום אמבוס' , 
title:'אטום 80 אחוז עם  דוגמה עדינה', 
price_to_show: 120,
pricesAndSizes: [ 
                {'desc': long,
                'price': 170,
                'no_colors': []},

                {'desc': short,
                'price': 120,
                'no_colors': []},
],
colors: ['לבן' ,'אופוויט','אפור','אבן'],
images: [require("./assets/xlSl45P.jpg"),
         require("./assets/827qXwF.jpg"),
         require("./assets/FH5JLr3.jpg"),
         require("./assets/QOvlkjf.jpg"),],
properties: ["curtain", "short", "long", "sunproof"],
}, 

{ name: 'וילון שוש' , 
title:'אטום 80 אחוז', 
price_to_show: 150,
pricesAndSizes: [ 
                {'desc': long,
                'price': 190,
                'no_colors': []},

                {'desc': short,
                'price': 150,
                'no_colors': []},
],
colors: ['כחול','אפור'],
images: [require("./assets/M21Nkh2.jpg"),
         require("./assets/onHZ0Qe.jpg"),
         require("./assets/dCdBsrq.jpg"),
         require("./assets/pEQEFg9.jpg"),
         require("./assets/iezwaFL.jpg")],
properties: ["curtain", "short", "long", "sunproof"],
},
 
{ name: 'וילון ענבר פלדים' , 
title:'וילון בד דמוי פשתן', 
price_to_show: undefined,
pricesAndSizes: [ 
                {'desc': paldim_450,
                'price': 'להצעת מחיר חייגו',
                'no_colors': []},

                {'desc': paldim_600,
                'price': 'להצעת מחיר חייגו',
                'no_colors': []},
],
colors: ['לבן'],
images: [require("./assets/inbar_paldim1.jpg"),
         require("./assets/inbar_paldim2.jpg"),],
properties: ["curtain", "long", "paldim","no_price"],
description: "וילון בד דמוי פשתן בטקסטורה עדינה "
},

// { name: "שטיח חבל סאמוס" , 
// price_to_show: 100,
// pricesAndSizes: [ 
//                   {'desc': '60 / 90 ס"מ',
//                    'price': 100,
//                    'no_colors': []},

//                   {'desc': '60 / 120 ס"מ',
//                    'price': 130,
//                    'no_colors': []},

//                    {'desc': '120 / 180 ס"מ',
//                    'price': 390,
//                    'no_colors': []},

//                   {'desc': '160 / 230 ס"מ',
//                    'price': 670,
//                    'no_colors': []},

//                    {'desc': '180 / 270 ס"מ',
//                    'price': 880,
//                    'no_colors': []},
// ],
// colors: ['אפור', 'שחור' , 'טבעי-01'],
// images: [require("./assets/ashram_samos.jpg"),
//         require("./assets/samos1.jpg"),
//         require("./assets/samos2.jpg"),
//         require("./assets/samos3.jpg"),
//         require("./assets/samos4.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח חבל עבה בשילוב פרנזים"
// },

{ name: 'ראנר חבל אגוס' , 
price_to_show: 70,
pricesAndSizes: [ 

                  {'desc': 'ראנר 110 / 35 ס"מ',
                  'price': 70,
                  'no_colors': []},

                  {'desc': 'ראנר 150 / 35 ס"מ',
                  'price': 100,
                  'no_colors': []},

                  {'desc': 'ראנר 180 / 35 ס"מ',
                  'price': 125,
                  'no_colors': []},
],
colors: ['אגוס-03','אגוס-02', 'אגוס-01'],
images: [require("./assets/ashram_agos.jpg"),
        ],
properties: ["map","accessories","prices"],
description: "מפת ראנר חבל טבעי"
},


{ name: 'וילון לונדון' , 
title:'וילון שתי שכבות בעל שכבת רשת', 
price_to_show: 160,
 pricesAndSizes: [ 

                  {'desc': short,
                  'price': 130,
                  'no_colors': []},

                  // {'desc': long,
                  // 'price': 160,
                  // 'no_colors': []},

                  {'desc': extraLong280,
                  'price': 180,
                  'no_colors': []},
],
colors: ['לבן', 'אפור'],
images: [require("./assets/london.jpg"),
         ],
properties: ["curtain", "short", "long", "extra long"],
},


{ name: 'סט אמנון' , 
title:' סט 4 חלקים מ 2 סוגי בדים שונים ', 
price_to_show: 260,
pricesAndSizes: [ 
                {'desc': set,
                'price': 260,
                'no_colors': []},
],
colors: ['שחור' ,'בורדו','מוקה','אפור'],
images: [require("./assets/oCZLyc4.jpg"),
         require("./assets/RBRZpS9.jpg"),
         require("./assets/S6GeBHy.jpg"),
         require("./assets/NvqIawy.jpg"),
         require("./assets/7eXXasV.jpg")],
properties: ["curtain", "long","set"],
}, 

   { name: 'וילון הדרה' , 
  title:'בד בעיטורים של פרחים לבנים',
  price_to_show: 120,
  pricesAndSizes: [ 
                  {'desc': long,
                  'price': 170,
                  'no_colors': []},

                  {'desc': short,
                  'price': 120,
                  'no_colors': []},

                  {'desc': koteret,
                  'price': 50,
                  'no_colors': []},

                  {'desc': extraWide,
                  'price': 170,
                  'no_colors': []},
],
  colors: ['לבן' ,'אופוויט'],
  images: [require("./assets/5ViuKvQ.jpg"),
           require("./assets/kKckVzc.jpg"),
           require("./assets/FIKyVeU.jpg"),
           require("./assets/Bio9xFT.jpg")],
  properties: ["curtain", "short", "long","extra wide","koteret"],
  description: 'וילון בד בעיטורים של פרחים לבנים ליצירת אוירה רכה בחדר או בסלון'
}, 


{ name: 'כרית פרח' , 
price_to_show: 30,
pricesAndSizes: [ {'desc': pillow_fill_standard,
                   'price': 30,
                   'no_colors': []},

                   {'desc': pillow_noFill_standard,
                   'price': 20,
                   'no_colors': []},

                   {'desc': pillow_fill_extra,
                   'price': 60,
                   'no_colors': []},

                   {'desc': pillow_noFill_extra,
                   'price': 35,
                   'no_colors': []},
],
colors: ['כמו בתמונה'],
images: [require("./assets/pillow_flower.jpg"),
         require("./assets/pillow_flower2.jpg"),
         require("./assets/pillow_flower3.jpg")
     ],
properties: ["pillow","accessories","prices"]
},

// { name: 'הדום ונצואלה' , 
// price_to_show: 320,
// pricesAndSizes: [ 

//                   {'desc': '45/45/45 ס"מ',
//                   'price': 320,
//                   'no_colors': []},
// ],
// colors: ['אבן', 'שמנת זהב', 'פודרה', 'שחור'],
// images: [require("./assets/ashram_venezuela.jpg"),
//         ],
// properties: ["hadom","accessories","prices"],
// description: "הדום ונצואלה - 100% כותנה אריגה"
// },


// { name: "שטיח דלתא" , 
// price_to_show: 120,
// pricesAndSizes: [ 
//                   {'desc': '70 / 130 ס"מ',
//                    'price': 120,
//                    'no_colors': []},

//                   {'desc': '120 / 180 ס"מ',
//                    'price': 300,
//                    'no_colors': []},

//                    {'desc': '160 / 230 ס"מ',
//                    'price': 490,
//                    'no_colors': []},

//                    {'desc': '200 / 300 ס"מ',
//                    'price': 800,
//                    'no_colors': []},
// ],
// colors: ['לבן'],
// images: [require("./assets/ashram_delta.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח 100% כותנה"
// },

// { name: "שטיח חבל איתמר" , 
// price_to_show: 120,
// pricesAndSizes: [ 

//                   {'desc': '60 / 120 ס"מ',
//                    'price': 120,
//                    'no_colors': []},

//                    {'desc': '120 / 180 ס"מ',
//                    'price': 330,
//                    'no_colors': []},

//                   {'desc': '160 / 230 ס"מ',
//                    'price': 570,
//                    'no_colors': []},

//                    {'desc': '200 / 300 ס"מ',
//                    'price': 930,
//                    'no_colors': []},

//                    {'desc': 'עגול 90 ס"מ',
//                    'price': 130,
//                    'no_colors': []},

//                    {'desc': 'עגול 120 ס"מ',
//                    'price': 220,
//                    'no_colors': []},

//                    {'desc': 'עגול 150 ס"מ',
//                    'price': 350,
//                    'no_colors': []},

//                    {'desc': 'עגול 180 ס"מ',
//                    'price': 490,
//                    'no_colors': []},

//                    {'desc': 'עגול 200 ס"מ',
//                    'price': 620,
//                    'no_colors': []},
// ],
// colors: ['03', '02' , '01'],
// images: [require("./assets/ashram_itamar.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח חבל טבעי"
// },

// { name: 'שטיח חבל ריו' , 
// price_to_show: 50,
// pricesAndSizes: [ {'desc': 'עגול 60 ס"מ',
//                   'price': 50,
//                   'no_colors': []},

//                   {'desc': 'עגול 100 ס"מ',
//                    'price': 150,
//                    'no_colors': []},

//                    {'desc': 'עגול 120 ס"מ',
//                    'price': 200,
//                    'no_colors': []},

//                    {'desc': 'עגול 150 ס"מ',
//                    'price': 290,
//                    'no_colors': []},

//                    {'desc': '60 / 120 ס"מ',
//                    'price': 110,
//                    'no_colors': []},

//                    {'desc': '120 / 180 ס"מ',
//                    'price': 290,
//                    'no_colors': []},

//                    {'desc': '160 / 230 ס"מ',
//                    'price': 450,
//                    'no_colors': []},

// ],
// colors: ['08', '05'],
// images: [require("./assets/ashram_rio.jpg"),
//         require("./assets/rio_fit.jpg"),
//         require("./assets/rio1.jpg"),
//         require("./assets/rio2.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח חבל טבעי"
// },


{ name: 'וילון אטום פס 3' , 
title:'אטום 100 אחוז', 
price_to_show: 150,
pricesAndSizes: [ 
                {'desc': long,
                'price': 200,
                'no_colors': []},

                {'desc': short,
                'price': 150,
                'no_colors': []},
],
colors: ['אופוויט','לבן'],
images: [require("./assets/LFI6t2z.jpg"),
         require("./assets/sO9GxJY.jpg"),],
properties: ["curtain", "long", "short", "sunproof"],
},


{ name: 'וילון אטום אמנון סינגל' , 
 title:' בד דוגמת שיש', 
 price_to_show: 120,
  pricesAndSizes: [ 
                  {'desc': long,
                  'price': 180,
                  'no_colors': []},

                  {'desc': short,
                  'price': 120,
                  'no_colors': []},
],
 colors: ['שחור-אפור' ,'בורדו-שחור','מוקה','אפור-תכלת'],
 images: [require("./assets/KhKLPmX.jpg"),
          require("./assets/NvqIawy.jpg"),
          require("./assets/RBRZpS9.jpg"),
          ],
 properties: ["curtain", "short", "long", "sunproof"],
},  


{ name: "וילון פיג'י פלדים" , 
title:'חצי שקוף דמוי פישתן טבעי', 
price_to_show: undefined,
pricesAndSizes: [ 
                {'desc': paldim_450,
                'price': 'להצעת מחיר חייגו',
                'no_colors': []},

                {'desc': paldim_600,
                'price': 'להצעת מחיר חייגו',
                'no_colors': []},
],
colors: ['אופוויט','אבן','אפור','חום'],
images: [require("./assets/YMiqOaA.jpg")],
properties: ["curtain", "long", "paldim","no_price"],
},

{ name: 'וילונות לסלון בהתאמה אישית' , 
price_long: null,
price_short: null,
colors: ['לפי קטלוג'],
images: [require("./assets/new_custom3.jpg"),
         require("./assets/new_custom7.jpg"),
         require("./assets/Hu2Zgvr.jpg"),
         require("./assets/custom1.jpg"),
         require("./assets/new_custom1.jpg"),
         require("./assets/new_custom2.jpg"),
         require("./assets/new_custom4.jpg"),
         require("./assets/new_custom5.jpg"),
         require("./assets/new_custom6.jpg"),
        ],
properties: ["curtain", "no_price"],
description: 'וילון לסלון בהתאמה אישית בבית הלקוח', 
},

// { name: 'שטיח תומפסון' , 
// price_to_show: 180,
// pricesAndSizes: [ 
//                   {'desc': 'עגול 120 ס"מ',
//                    'price': 180,
//                    'no_colors': []},

//                   {'desc': '120 / 180 ס"מ',
//                    'price': 270,
//                    'no_colors': []},

//                    {'desc': '160 / 230 ס"מ',
//                    'price': 450,
//                    'no_colors': []},

//                    {'desc': '200 / 300 ס"מ',
//                    'price': 720,
//                    'no_colors': []},

                   
// ],
// colors: ['בז','אפור','חרדל'],
// images: [require("./assets/thompson_fit.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח כותנה ג'קארד"
// },

{ name: 'כרית פשתן' , 
price_to_show: 30,
pricesAndSizes: [ {'desc': pillow_fill_standard,
                   'price': 30,
                   'no_colors': []},

                   {'desc': pillow_noFill_standard,
                   'price': 20,
                   'no_colors': []},

                   {'desc': pillow_fill_extra,
                   'price': 60,
                   'no_colors': []},

                   {'desc': pillow_noFill_extra,
                   'price': 35,
                   'no_colors': []},
],
colors: ['שחור' , 'אפור' ,'לבן' ,'בז' ,'אדום'],
images: [require("./assets/pillow_pishtan.jpg"),
        require("./assets/pillow_pishtan_red.jpg"),
        require("./assets/pillow_pishtan_beige.jpg"),
        require("./assets/pillow_pishtan_white.jpg"),
     ],
properties: ["pillow","accessories","prices"]
},

{ name: 'כרית דמוי עור' , 
price_to_show: 30,
colors: ['שחור'],
pricesAndSizes: [ {'desc': pillow_fill_standard,
                   'price': 30,
                   'no_colors': []},

                   {'desc': pillow_noFill_standard,
                   'price': 20,
                   'no_colors': []},
],
images: [require("./assets/l4afaDX.jpg"),
      ],
properties: ["pillow","accessories","prices"],
},










// { name: 'שטיח פרווה' , 
// price_to_show: 70,
// pricesAndSizes: [ {'desc': '60 / 60 ס"מ',
//                    'price': 70,
//                    'no_colors': []},

//                    {'desc': '60 / 90 ס"מ',
//                    'price': 90,
//                    'no_colors': []},

//                    {'desc': '90 / 90 ס"מ',
//                    'price': 120,
//                    'no_colors': []},

//                    {'desc': '90 / 150 ס"מ',
//                    'price': 180,
//                    'no_colors': []},

//                    {'desc': '150 / 220 ס"מ',
//                    'price': 400,
//                    'no_colors': []},
// ], 
// colors: ['שחור' , 'אפור' ,'לבן' ,'בז' ,'אדום'],
// images: [require("./assets/fur2.jpg"),
//          require("./assets/fur3.jpg"),
//          require("./assets/fur1.jpg"),  
//      ],
// properties: ["carpet","accessories","prices", "out_of_stock"],
// },

// { name: 'שטיח מודרני עגול' , 
// price_to_show: 180,
// pricesAndSizes: [ {'desc': '120 / 120 ס"מ',
//                    'price': 180,
//                    'no_colors': []},
// ],
// colors: ['חום','תכלת'],
// images: [require("./assets/modern.jpg"),
//          require("./assets/8XtyS6r.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח מודרני בצבעים אופנתיים, קל לניקוי ולשטיפה"
// },

// { name: 'שטיח מודרני מלבן' , 
// price_to_show: 160,
// pricesAndSizes: [ {'desc': '80 / 150 ס"מ',
//                    'price': 160,
//                    'no_colors': []},

//                    {'desc': '120 / 160 ס"מ',
//                    'price': 240,
//                    'no_colors': []},

//                    {'desc': '160 / 220 ס"מ',
//                    'price': 400,
//                    'no_colors': []},
// ],
// colors: ['חום','תכלת'],
// images: [require("./assets/modernBlue3.jpg"),
//          require("./assets/ozdd8JX.jpg"),
//          require("./assets/modernBlue2.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח מודרני בצבעים אופנתיים, קל לניקוי ולשטיפה"
// },

// { name: 'שטיח מודרני' , 
// price_to_show: 140,
// pricesAndSizes: [ 
//                   {'desc': 'עגול 120 ס"מ',
//                    'price': 170,
//                    'no_colors': []},

//                    {'desc': '80 / 150 ס"מ',
//                    'price': 140,
//                    'no_colors': []},

//                   {'desc': '120 / 160 ס"מ',
//                    'price': 220,
//                    'no_colors': []},

//                    {'desc': '160 / 220 ס"מ',
//                    'price': 410,
//                    'no_colors': []},

//                    {'desc': '200 / 290 ס"מ',
//                    'price': 670,
//                    'no_colors': []},
// ],
// colors: ['בז','אפור','תכלת'],
// images: [require("./assets/carpet1.jpg"),
//          require("./assets/carpet2.jpg"),
//          require("./assets/carpet3.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח מודרני בצבעים אופנתיים, קל לניקוי ולשטיפה"
// },

// { name: 'שטיח דג פרווה' , 
// price_to_show: 180,

// colors: ['לבן','אפור','אדום'],
// images: [require("./assets/fish1.jpg"),
//          require("./assets/fish2.jpg"),
//         ],
// properties: ["carpet","accessories","prices", "out_of_stock"],
// description: "שטיח כותנה ג'קארד"
// },

// { name: 'שטיח פריז' , 
// price_to_show: 160,
// pricesAndSizes: [ 
//                   {'desc': 'עגול 120 ס"מ',
//                   'price': 200,
//                   'no_colors': []},

//                   {'desc': '80 / 150 ס"מ',
//                   'price': 160,
//                   'no_colors': []},

//                 {'desc': '120 / 160 ס"מ',
//                   'price': 260,
//                   'no_colors': []},

//                   {'desc': '160 / 220 ס"מ',
//                   'price': 490,
//                   'no_colors': []},

//                   {'desc': '200 / 290 ס"מ',
//                   'price': 800,
//                   'no_colors': []}
// ],
// colors: ['אפור','חום'],
// images: [require("./assets/lines1.jpg"),
//          require("./assets/lines2.jpg"),
//          require("./assets/lines3.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח אריגה צפופה איכותי"
// },

// { name: 'שטיח לונדון' , 
// price_to_show: 160,
// pricesAndSizes: [ 
//                   {'desc': 'עגול 120 ס"מ',
//                   'price': 200,
//                   'no_colors': []},

//                   {'desc': '80 / 150 ס"מ',
//                   'price': 160,
//                   'no_colors': []},

//                 {'desc': '120 / 160 ס"מ',
//                   'price': 260,
//                   'no_colors': []},

//                   {'desc': '160 / 220 ס"מ',
//                   'price': 490,
//                   'no_colors': []},

//                   {'desc': '200 / 290 ס"מ',
//                   'price': 800,
//                   'no_colors': []}
// ],
// colors: ['אפור'],
// images: [require("./assets/newSquares.jpg"),
//          require("./assets/newSquares2.jpg"),
//          require("./assets/newSquares3.jpg"),
//          require("./assets/newSquares4.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: "שטיח לונדון ריבועים, אריגה צפופה ואיכותית. קל ומהיר לניקוי"
// },

// { name: 'ראנר חבל שקד' , 
// price_to_show: 50,
// pricesAndSizes: [ 

//                   {'desc': 'ראנר 100 / 30 ס"מ',
//                   'price': 50,
//                   'no_colors': []},

//                   {'desc': 'ראנר 150 / 30 ס"מ',
//                   'price': 70,
//                   'no_colors': []},
// ],
// colors: ['חום-02', 'טבעי-01'],
// images: [require("./assets/ashram_shaked.jpg"),
//          require("./assets/ashram_shaked2.jpg"),
//         ],
// properties: ["map","accessories","prices"],
// description: "מפת ראנר חבל משולב פרנזים"
// },



// { name: 'ראנר חבל טהיטי' , 
// price_to_show: 60,
// pricesAndSizes: [ 

//                   {'desc': 'ראנר 110 / 35 ס"מ',
//                   'price': 60,
//                   'no_colors': []},

//                   {'desc': 'ראנר 150 / 34 ס"מ',
//                   'price': 75,
//                   'no_colors': []},
// ],
// colors: ['טבעי'],
// images: [require("./assets/ashram_tahiti2.jpg"),
//         ],
// properties: ["map","accessories","prices"],
// description: "מפת ראנר חבל טבעי בשילוב זהב"
// },



// { name: 'הדום דלתא' , 
// price_to_show: 300,
// pricesAndSizes: [ 

//                   {'desc': '45/45/45 ס"מ',
//                   'price': 300,
//                   'no_colors': []},
// ],
// colors: ['לבן-שחור'],
// images: [require("./assets/ashram_delta.jpg"),
//         ],
// properties: ["hadom","accessories","prices"],
// description: "הדום דלתא 100% כותנה"
// },

// { name: 'הדום יהלום' , 
// price_to_show: 300,
// pricesAndSizes: [ 

//                   {'desc': '45/45/45 ס"מ',
//                   'price': 300,
//                   'no_colors': []},

//                   {'desc': '40/60/30 ס"מ',
//                   'price': 300,
//                   'no_colors': []},
// ],
// colors: ['לבן-שחור'],
// images: [require("./assets/ashram_diamond.jpg"),
//         ],
// properties: ["hadom","accessories","prices"],
// description: "הדום 100% כותנה"
// },

// { name: 'הדום טהיטי' , 
// price_to_show: 270,
// pricesAndSizes: [ 

//                   {'desc': '40/35 ס"מ',
//                   'price': 270,
//                   'no_colors': []},
// ],
// colors: ['טבעי'],
// images: [require("./assets/ashram_tahiti.jpg"),
//         ],
// properties: ["hadom","accessories","prices"],
// description: "הדום טהיטי - חבל טבעי בשילוב זהב"
// },

// { name: 'שטיח מדריד' , 
// price_to_show: 160,
// pricesAndSizes: [ 
//                   {'desc': 'עגול 120 ס"מ',
//                   'price': 200,
//                   'no_colors': []},

//                   {'desc': '80 / 150 ס"מ',
//                   'price': 160,
//                   'no_colors': []},

//                 {'desc': '120 / 160 ס"מ',
//                   'price': 260,
//                   'no_colors': []},

//                   {'desc': '160 / 220 ס"מ',
//                   'price': 490,
//                   'no_colors': []},

//                   {'desc': '200 / 290 ס"מ',
//                   'price': 800,
//                   'no_colors': []}
// ],
// colors: ['חום-אופוויט','אפור-בורדו','אפור-שחור','אפור-צהוב'],
// images: [require("./assets/squares1.jpg"),
//          require("./assets/squares2.jpg"),
//          require("./assets/squares3.jpg"),
//          require("./assets/squares4.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// description: " שטיח מדריד ריבועים אריגה צפופה ואיכותית"
// },

 { name: 'וילון סיגל' , 
 title:'תחרה', 
 price_to_show: 130,
 pricesAndSizes: [ 
                 {'desc': long,
                 'price': 170,
                 'no_colors': []},
 
                 {'desc': short,
                 'price': 130,
                 'no_colors': []},
 
                 {'desc': extraWide,
                 'price': 170,
                 'no_colors': []},
 ],
 colors: ['לבן' ,'אופוויט','מוקה'],
 images: [require("./assets/zGEqTLX.jpg"),
          require("./assets/Kg5SgeJ.jpg")],
 properties: ["curtain", "short", "long", "extra wide"],
 },


// { name: 'סט דורית' , 
// title:'סט 4 חלקים מ 2 סוגי בדים שונים ', 
// price_to_show: 260,
// pricesAndSizes: [ 
//                 {'desc': set,
//                 'price': 260,
//                 'no_colors': []},
// ],
// colors: ['מוקה'],
// images: [require("./assets/dorit.jpg")],
// properties: ["curtain", "long","set"],
// }, 





// { name: 'שטיח חבל עגול' , 
// price_to_show: 120,
// pricesAndSizes: [ 
//                   {'desc': 'עגול 100 ס"מ',
//                    'price': 120,
//                    'no_colors': []},

//                   {'desc': 'עגול 120 ס"מ',
//                    'price': 180,
//                    'no_colors': []},

//                    {'desc': 'עגול 150 ס"מ',
//                    'price': 280,
//                    'no_colors': []},
// ],
// colors: ['טבעי-08'],
// images: [require("./assets/rope.jpg"),
//          require("./assets/rope2.jpg"),
//          require("./assets/rope3.jpg"),
//          require("./assets/rope4.jpg"),
//         ],
// properties: ["carpet","accessories","prices"],
// },


];