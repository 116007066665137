import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { store } from './store';
import VueRouter from 'vue-router'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTrash, faTimes, faPlus, faMinus, faShoppingCart, faShoppingBag, faCheckCircle, faTimesCircle, 
        faEllipsisV, faBars, faSearch, faShippingFast, faStar, faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import Toasted from 'vue-toasted';
import Vuelidate from 'vuelidate'
import VueLazyload from 'vue-lazyload'
import 'aos/dist/aos.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

library.add(faTrash)
library.add(faTimes)
library.add(faPlus)
library.add(faMinus)
library.add(faShoppingCart)
library.add(faShoppingBag)
library.add(faCheckCircle)
library.add(faTimesCircle)
library.add(faEllipsisV)
library.add(faBars)
library.add(faSearch)
library.add(faShippingFast)
library.add(faStar)
library.add(faQuoteRight)

Vue.use(Vuelidate)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueRouter);
Vue.use(Toasted, {iconPack : 'material'})
Vue.use(VueLazyload);
Vue.use(VueSweetalert2);
Vue.use(Viewer)

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
