import Vue from 'vue';
import Vuex from 'vuex';
import { products_const } from './items_data.js';
import createPersistedState from "vuex-persistedstate";
import createMutationsSharer from "vuex-shared-mutations";

Vue.use(Vuex);

export const store = new Vuex.Store({
  plugins: [createPersistedState({paths: ['cart', 'shippingPrice'], storage: window.localStorage }), 
            createMutationsSharer({ predicate: ["addItemToCart", "updateCart", "deleteItemFromCart", "changeQuantityOfItem", "updateCoupon"] })],
  state: {
    products: products_const,
    filteredArray : products_const,
    isSearchFilter : false,
    cart : [],
    isOrderSuccess: true,
    shippingPrice: 0,
    transactionID: '',
    coupon: 0
  },
  mutations: {
      updateFilteredArray (state, value) {
        state.filteredArray = value;
      },
      updateIsSearchFilter (state, value) {
        state.isSearchFilter = value;
      },
      updateCart (state, value) {
        state.cart = value;
      },
      addItemToCart (state, value) {
        state.cart.push(value);
      },
      deleteItemFromCart (state, index) {
        state.cart.splice(index, 1);
      },
      changeQuantityOfItem (state, payload) {
        state.cart[payload.index].quantity = payload.newQuantity;
      },
      updateIsOrderSuccess (state, value) {
        state.isOrderSuccess = value;
      },
      updateShippingPrice (state, value) {
        state.shippingPrice = value;
      },
      updateTransactionID (state, value) {
        state.transactionID = value;
      },
      updateCoupon (state, value) {
        state.coupon = value;
      },
  },
  actions: {
    initFilteredArray({ commit }, value) {
      commit('updateFilteredArray', value);
     },

     setIsSearchFilter({ commit }, value) {
      commit('updateIsSearchFilter', value);
     },

    setCart({ commit }, value) {
      commit('updateCart', value);
    },

    addToCart({ commit }, value) {
      commit('addItemToCart', value);
    },

    deleteFromCart({ commit }, value) {
      commit('deleteItemFromCart', value);
    },

    changeQuantity({ commit }, payload) {
      commit('changeQuantityOfItem', payload);
    },

    submitOrder({ commit }, payload) {
      let total = 0;
      total = this.state.cart.reduce(
        (a, b) => a + b.price * b.quantity,
        0
      );

      let finalPrice = 0;

      if(this.state.coupon !== 0) {
        finalPrice = (total * (100 - this.state.coupon) / 100) + parseInt(this.state.shippingPrice);
      } else {
        finalPrice = total + parseInt(this.state.shippingPrice);
      }

      let finalAddress = payload.street + ", "  + payload.city;
      finalAddress = finalAddress.toString();

      window.location = 'https://www.allforhome.co.il/sendMailWithLogs.php?firstName=' + payload.firstName + "&lastName=" 
        + payload.lastName + "&phone=" + payload.phone + "&comments=" + payload.comments+ "&email=" +payload.email+
        "&address=" + finalAddress + "&totalProductPrice=" + total + 
        "&shippingCost=" + this.state.shippingPrice + "&coupon=" + this.state.coupon +
         "&totalPrice=" + finalPrice +
         "&cart=" + encodeURIComponent(JSON.stringify(this.state.cart))+ "&uuid=" + this.state.transactionID  + "&date=" + store.getters.getDate;
       commit('updateIsOrderSuccess', true);

    },

    submitBitRequest({ commit }, payload) {
      let total = 0;
      total = this.state.cart.reduce(
        (a, b) => a + b.price * b.quantity,
        0
      )

      let finalPrice = 0;

      if(this.state.coupon !== 0) {
        finalPrice = (total * (100 - this.state.coupon) / 100) + parseInt(this.state.shippingPrice);
      } else {
        finalPrice = total + parseInt(this.state.shippingPrice);
      }
      
      let finalAddress = payload.street + ", "  + payload.city;
      finalAddress = finalAddress.toString();

      window.location = 'https://www.allforhome.co.il/bitRequest.php?firstName=' + payload.firstName + "&lastName=" 
        + payload.lastName + "&phone=" + payload.phone + "&comments=" + payload.comments + "&email=" + payload.email +
        "&address=" + finalAddress + "&totalProductPrice=" + total + 
        "&shippingCost=" + this.state.shippingPrice + "&coupon=" + this.state.coupon +
         "&totalPrice=" + finalPrice +
         "&cart=" + encodeURIComponent(JSON.stringify(this.state.cart)) + "&uuid=" + this.state.transactionID  + "&date=" + store.getters.getDate;
         commit('updateIsOrderSuccess', true);
    },
    
    changeOrderSuccess({ commit }, value) {
      commit('updateIsOrderSuccess', value);
    },
    changeShippingPrice({ commit }, value) {
      commit('updateShippingPrice', value);
    },
    changeTransactionID({ commit }, value) {
      commit('updateTransactionID', value);
    },
    changeCoupon({ commit }, value) {
      commit('updateCoupon', value);
    },
  },
  getters: {
    getDate() {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date + ' ' + time;
    },

  }
});