<template>
  <div id="info">
          <title>וילונות מעצבים חללים - אודות</title>

    <div class="containers">
      <header>
        <h1 class="knockout mt-1">אודות</h1>
      </header>
      <br>

<h5>כולנו אוהבים סביבת מגורים ועבודה המותאמים להעדפות האישיות שלנו.</h5>
<h5>וילונות הינם פריט עיצוב שיכול לחולל פלאים,</h5>
<h5>באמצעותם נוכל לתת ביטוי לטעם האישי שלנו ולייצר את המרחב והאווירה הרצויים לנו.   </h5>
<h5>אנו עוסקים בתחום מזה 30 שנה, ומציעים שירות מקצועי אמין ואיכותי כאשר הלקוח שלנו נמצא תמיד במרכז. </h5>
<h5>השירות שלנו ניתן על בסיס אישי ובפגישה בבית הלקוח או במשרדו.</h5>
<h5>אנו לומדים על הלקוח ועל צרכיו, כגון: האפלה, הצללה, תיחום, נוי ואוירה מתאימה.</h5>
<h5>אנו עובדים עם וילונות ובדים איכותיים במגוון של סגנונות כגון: ונציאני, רומאי, גלילה, בדים להצללה, לאטימה ומציעים וילונות מוכנים או בהתאמה אישית ובהתאם להעדפת הלקוח ולתקציב העומד לרשותו.</h5>
<h3 class="special">פנו אלינו עוד היום לתיאום פגישה ללא התחייבות! </h3>

<br>

<h3>צור קשר:</h3>
<h4>וילונות מעצבים חללים</h4>
<h4><a class="phone" href="tel:058-551-5533">פלאפון: 058-551-5533</a></h4>
<h4>אימייל: allforhome.contact@gmail.com</h4>

<!-- <b-button pill class="btn btn-light mybtn mt-4" type="button"><a href="https://www.facebook.com/%D7%95%D7%99%D7%9C%D7%95%D7%A0%D7%95%D7%AA-%D7%9E%D7%A2%D7%A6%D7%91%D7%99%D7%9D-%D7%97%D7%9C%D7%9C%D7%99%D7%9D-466824004102896/?hc_ref=ARQ7xf-fMwrPlyBLN04EsILWKKjsWtF6QlSw1wkiNv7VXOXeiXGJpKxOS01yBXiLXtI&fref=nf&__tn__=kC-R"> וילונות מעצבים חללים בפייסבוק</a></b-button> -->
<div class="buttons">
<a target="_blank" href="https://www.facebook.com/%D7%95%D7%99%D7%9C%D7%95%D7%A0%D7%95%D7%AA-%D7%9E%D7%A2%D7%A6%D7%91%D7%99%D7%9D-%D7%97%D7%9C%D7%9C%D7%99%D7%9D-466824004102896/?hc_ref=ARQi0LMq8plikgcztTTJIWfegNbabfKL1VCyMlnt7EgaJ7WkNdXbW-BmsznUmMHV1JQ&fref=nf&__tn__=kC-R4102896" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-size:14px;text-decoration:none;color:#FFFFFF;"><img title="Facebook" src="https://fqtemn.stripocdn.email/content/assets/img/social-icons/circle-colored/facebook-circle-colored.png" alt="Fb" width="32" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;"></a>
<a class="instagram" target="_blank" href="https://www.instagram.com/allforhome.co.il/" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-size:14px;text-decoration:none;color:#FFFFFF;"><img title="Instagram" src="https://fqtemn.stripocdn.email/content/assets/img/social-icons/circle-colored/instagram-circle-colored.png" alt="Ig" width="32" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;"></a>
</div>                        

    </div>
  </div>
</template>
<script>

export default {
  methods: {
    
   scrollToTop() {
        window.scrollTo(0,0);
   },
  },
  created() {
    this.scrollToTop();
  }
}

</script>

<style scoped>
#info {
  background: #e3e3e6;
}
.knockout {
  background: url("../assets/coma_misti.jpg") -10px -80px;
  color: red;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: bold;
  font-size: 65px;
  width: 100%;
  margin: 0px auto;
  text-align: center;
  margin-top: -25px;
  padding-top: 5%;
  padding-bottom: 0%;
}

.containers {
padding-right:22%;
padding-left:22%;
padding-bottom: 5%;
}

@media screen and (max-width: 985px) {

.containers {
padding-right:8%;
padding-left:8%;
padding-bottom: 10%;

}
.knockout {
  background: url("../assets/coma_misti.jpg") -80px -80px;
  color: red;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: bold;
  font-size: 30px;
  width: 100%;
  margin: 0px auto;
  text-align: center;
  margin-top: -25px;
  margin-bottom: -15px;
  padding-top: 15%;
  padding-bottom: 5%;
}
}
h3 {
    color: rgb(125, 12, 12);
}

h4 {
    color: rgba(130, 7, 7, 0.842);
}
.phone {
   color: rgba(130, 7, 7, 0.842);
}
.special {
    color: rgb(125, 12, 12);
    padding-top: 2%;
}
.instagram {
  margin-right: 2%;
}
.buttons {
  display: inline-flex;
  margin-top: 20px;
}
h5 {
  margin-top: 1%;
}
h4, .h4 {
    font-size: 1.2rem;
}
</style>
