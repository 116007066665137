<template>
<div id="reviews">

  <div class="knockout main_title">מה אמרו עלינו?</div>

  <div class="knockout second_title">אל תסמכו רק עלינו, תסמכו על מה שהלקוחות אומרים!</div>



<div class="main">
  <span class="sub_title">ביקורות מהפייסבוק</span>
  <ul class="cards">

    <li class="cards_item">
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fhadar.rhamim%2Fposts%2F3418056631632487&width=500&show_text=true&height=230&appId" width="500" height="260" style="border:none;overflow:hidden" data-lazy="true"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </li>
     
    <li class="cards_item">
     <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Falon.ofir.54%2Fposts%2F10221543216103203&width=500&show_text=true&height=193&appId" width="500" height="210" style="border:none;overflow:hidden" class="long_post" data-lazy="true"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </li>


    <li class="cards_item">
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fkarin.kallner%2Fposts%2F10224170023507121&width=500&show_text=true&height=174&appId" width="500" height="224" style="border:none;overflow:hidden" data-lazy="true"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </li>

    <li class="cards_item">
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fkoral.gorgevich%2Fposts%2F4422900371059144&width=500&show_text=false&height=174&appId" width="500" height="204" style="border:none;overflow:hidden" data-lazy="true" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </li>

    <li class="cards_item">
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsapir.shemesh.3%2Fposts%2F4455450837804294&width=500&show_text=true&height=174&appId" width="500" height="184" style="border:none;overflow:hidden" data-lazy="true"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </li>

    <li class="cards_item">
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D2778632699091520%26id%3D100008344502641&width=500&show_text=true&height=155&appId" width="500" height="185" style="border:none;overflow:hidden" data-lazy="true"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </li>

<li class="cards_item">
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fnoam.elkobi%2Fposts%2F3037551529637450&show_text=true&width=552&height=193&appId" width="552" height="220" style="border:none;overflow:hidden" data-lazy="true"  scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
    </li>
    
       <li class="cards_item">
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fatalya.t.edri%2Fposts%2F10224055777962081&width=500&show_text=true&height=533&appId" width="500" height="543" class="image_horizontal" style="border:none;overflow:hidden" data-lazy="true"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </li>

        <li class="cards_item">
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Feinav.mizr%2Fposts%2F10222753440238554&width=500&show_text=true&height=675&appId" width="500" height="685" class="image_vertical_first" style="border:none;overflow:hidden" data-lazy="true"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </li>

    <li class="cards_item">
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsapiri.yaakov%2Fposts%2F3782176635147744&width=500&show_text=true&height=675&appId" width="500" height="685" class="image_vertical_second" style="border:none;overflow:hidden" data-lazy="true"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </li>

    <li class="cards_item">
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmay.levi.7%2Fposts%2F3762925220404638&width=500&show_text=true&height=174&appId" width="500" height="184" style="border:none;overflow:hidden" data-lazy="true"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </li>

    <li class="cards_item">
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fhila.capon%2Fposts%2F4887771527901509&width=500&show_text=true&height=155&appId" width="500" height="165" style="border:none;overflow:hidden" data-lazy="true"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </li>

    <li class="cards_item">
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D2932597516779603%26id%3D100000881494076&width=500&show_text=true&height=137&appId" width="500" height="147" style="border:none;overflow:hidden" data-lazy="true"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </li>

    <li class="cards_item">
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D2452497788185344%26id%3D100002754398908&show_text=true&width=552&height=174&appId" width="552" height="184" style="border:none;overflow:hidden" data-lazy="true"  scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
    </li>

    <li class="cards_item">
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fizi.bichacho%2Fposts%2F10158059772297118&show_text=true&width=552&height=155&appId" width="552" height="165" style="border:none;overflow:hidden" data-lazy="true"  scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe> 
    </li>

  </ul>
</div>





</div>
</template>

<script>

export default {
  methods: {

   scrollToTop() {
        window.scrollTo(0,0);
   },
  },
  created() {
    this.scrollToTop();
    }
}

</script>

<style scoped>
#reviews{
    margin-top: 50px;
    padding-right: 3%;
    padding-left: 3%;
}

.knockout {
  background: url("../assets/coma_misti.jpg") -0px -10px;
  color: red;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin: auto;
}

.main_title {
  font-size: calc(1em + 1.6vw);
}

.second_title {
  font-size: calc(0.8em + 1vw);
}

iframe {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  overflow:scroll;
  margin-top: 3%;
  /* margin-bottom: 5px; */
}

.sub_title {
  margin-top: 5%;
  font-weight: 700;
  font-size: calc(1em + 1.5vw);
  color: #0069bd;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 2%;
  padding-bottom: 5%;
}

.cards_item {
  padding: 1rem;
}

@media (min-width: 40rem) {
  .cards_item {
    /* width: 50%; */
    width: 100%;
  }
}

@media (min-width: 56rem) {
  .cards_item {
    /* width: 33.3333%; */
    width: 50%;
  }
}

.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0,0,0,.3);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.main{
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 4%;
}

@media only screen and (max-width: 570px) {
.image_horizontal {
  height: 98vw;
}
}

@media only screen and (max-width: 500px) {
.image_horizontal {
  height: 102vw;
}
}

@media only screen and (max-width: 450px) {
.image_vertical_second {
  height: 158vw;
}
}

@media only screen and (max-width: 430px) {
.image_horizontal {
  height: 110vw;
}
}

@media only screen and (max-width: 385px) {
.image_horizontal {
  height: 116vw;
}
.image_vertical_first {
  height: 190vw;
}
.image_vertical_second {
  height: 162vw;
}
.long_post {
  height: 230px;
}
}

@media only screen and (max-width: 335px) {
.image_horizontal {
  height: 125vw;
}
.image_vertical_first {
  height: 200vw;
}
.image_vertical_second {
  height: 170vw;
}
}

.cards_item {
  padding: 1rem;
}

@media (min-width: 40rem) {
  .cards_item {
    /* width: 50%; */
    width: 100%;
  }
}

@media (min-width: 56rem) {
  .cards_item {
    /* width: 33.3333%; */
    width: 50%;
  }
}
.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 2%;
  padding-bottom: 5%;
}
</style>