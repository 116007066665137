<template>
<div id="test">
    
<title>AllForHome | וילונות מעצבים חללים</title>

<div id="viewport" content="width=device-width, initial-scale=1.0">


  <div class="linkSection linkColor">
    <router-link class="linkColor" to="/">בית</router-link> /
    <span v-if="!this.$route.params.mainCategory"> קטלוג </span> 
    <span v-if="this.$route.params.mainCategory"> 
      <router-link class="linkColor" to="/קטלוג">קטלוג</router-link> / 
      <span v-if="!this.$route.params.subCategory" class="linkColor">{{this.$route.params.mainCategory}}</span>
      <span v-if="this.$route.params.subCategory">
        <router-link :to="this.getMainCatalogPath()" class="linkColor">{{this.$route.params.mainCategory}}</router-link> /
        <span class="linkColor">{{this.$route.params.subCategory}}</span>
      </span>
      
    </span>
  </div>


  <b-button pill variant="outline-secondary" class="isImageMenu" @click="imageMenuClicked()">קטגוריות</b-button>


<div v-if="showImageMenu" class="menu row float-center top-menu">

  <!-- Menu Item -->
    <div class="ml-3 mr-3">
      <router-link :to="{ name: 'catalog', params: {mainCategory:'וילונות' , subCategory: 'וילונות-ארוכים' } }">
 <div class="menuCard card" @click="pushCategory()">
  <img src="../assets/crepe_back4.jpg" class="card-img-top menuCard" >
<div class="menuOverlay">
    <div class="text">בחר</div>
  </div>
</div>
<p class="text-dark menu-text mt-1" data-locator="categoriestitle">וילונות ארוכים</p>
</router-link>
</div>

  <!-- Menu Item -->
    <div class="ml-3 mr-3">
<router-link :to="{ name: 'catalog', params: {mainCategory:'וילונות' , subCategory: 'וילונות-קצרים'} }">
 <div class="menuCard card" @click="pushCategory()">
  <img src="../assets/crepe_back.jpg" class="card-img-top menuCard" >
<div class="menuOverlay">
    <div class="text">בחר</div>
  </div>
  </div>
  <p class="text-dark menu-text mt-1" data-locator="categoriestitle">וילונות קצרים</p>
</router-link>
</div>


  <!-- Menu Item -->
      <div class="ml-3 mr-3">
      <router-link :to="{ name: 'catalog', params: {mainCategory:'וילונות' , subCategory: 'וילונות-אטומים'} }">
 <div class="menuCard card" @click="pushCategory()">
  <img src="../assets/aRt5TL4.jpg" class="card-img-top menuCard" >

<div class="menuOverlay">
    <div class="text">בחר</div>
  </div>
</div>
  <p class="text-dark menu-text mt-1" data-locator="categoriestitle">וילונות אטומים</p>
  </router-link>
</div>

  <!-- Menu Item -->
      <div class="ml-3 mr-3">
      <router-link :to="{ name: 'catalog', params: {mainCategory:'וילונות' , subCategory: 'סט-4-חלקים'} }">
 <div class="menuCard card" @click="pushCategory()">
  <img src="../assets/oCZLyc4.jpg" class="card-img-top menuCard" >
<div class="menuOverlay">
    <div class="text">בחר</div>
  </div>
</div>
  <p class="text-dark menu-text mt-1" data-locator="categoriestitle">סט 4 חלקים</p>
      </router-link>
</div>

  <!-- Menu Item -->
    <div class="ml-3 mr-3">
        <router-link :to="{ name: 'catalog', params: {mainCategory:'וילונות' , subCategory: 'וילונות-פלדים-על-מסילה' } }">
      <div class="menuCard card" @click="pushCategory()">
        <img src="../assets/pina_paldim.jpg" class="card-img-top menuCard" >
      <div class="menuOverlay">
          <div class="text">בחר</div>
        </div>
      </div>
        <p class="text-dark menu-text mt-1" data-locator="categoriestitle">וילונות פלדים</p>
          </router-link>
    </div>


  <!-- Menu Item -->
      <div class="ml-3 mr-3">
  <router-link :to="{ name: 'product', params: {category:'וילונות' , name: 'וילון-גלילה-זברה' } }">
 <div class="menuCard card">
  <img src="../assets/qsk901w.jpg" class="card-img-top menuCard  " >
<div class="menuOverlay">
    <div class="text">בחר</div>
  </div>
</div>
  <p class="text-dark menu-text mt-1" data-locator="categoriestitle"> וילון זברה </p>
  </router-link>
</div>

  <!-- Menu Item -->
      <div class="ml-3 mr-3">
     <router-link :to="{ name: 'accessories_catalog', params: {mainCategory:'כריות'} }">
 <div class="menuCard card" @click="pushCategory('כריות')">
  <img src="../assets/pillow_corderoy.jpg" class="card-img-top menuCard" >
<div class="menuOverlay">
    <div class="text">בחר</div>
  </div>
</div>
  <p class="text-dark menu-text mt-1" data-locator="categoriestitle">כריות</p>
     </router-link>
</div>

  <!-- Menu Item -->
      <div class="ml-3 mr-3">
        <router-link :to="{ name: 'accessories_catalog', params: {mainCategory:'שטיחים'} }">
 <div class="menuCard card" @click="pushCategory()">
  <img src="../assets/8XtyS6r.jpg" class="card-img-top menuCard" >
<div class="menuOverlay">
    <div class="text">בחר</div>
  </div>
</div>
  <p class="text-dark menu-text mt-1" data-locator="categoriestitle">שטיחים</p>
  </router-link>
</div>

</div>

<div class="catalog">
<hr class="hrTop"/>
<header>
  <h1 class="knockout">{{this.pageTitle}}</h1>
</header>
<hr class="hrTop"/>
</div>

  <!-- Content -->
  <div id="content" content="width=device-width, initial-scale=1.0">

<!-- <h1 class="row row1" v-for="row in this.rows" :key="row"> -->

<h1 class="name notFound" v-if="this.filteredArray.length === 0">לא נמצאו פריטים</h1>

    <div class="tab-pane show active pt-2 pr-3 pl-3" id="best-seller" role="tabpanel">
        <div class="row">

          <div v-for="product in filteredArray" :key="product.name" class="col-xs-3 col-sm-6 col-md-4 col-lg-3 m-b-20 cardWidth mycard">
          <router-link :to="{ name: 'product', params: {category: getProductCategoryForUrl(product), name: product.name.replace(/ /g,'-') } }">

          <div class="block2 card h-100 cardDesign">
            <div class="card-body">
            <!-- 
              block2-labelsale - sale tag
              block2-labelnew - new tag
              -->
              <div class="block2-img wrap-pic-w of-hidden pos-relative">
                  <img class="img lazy" v-lazy="product.images[0]" :alt="product.name">
                  
                  <div class="block2-overlay trans-0-4"></div>
              </div>

              <hr>

            <div id="container">

              <div>
                <h6 class="colors cardText pt-2" aria-hidden="true">
                  <div style="display: inline">{{product.colors.length}} צבעים  </div>
                  <div style="display: inline" v-if="isProductByString(product,'short')">| קצר </div>
                  <div style="display: inline" v-if="isProductByString(product, 'long')">|  ארוך  </div>
                </h6>
              </div>

                <div class="middle" v-if="product.title === undefined"><h4 class="name">{{product.name}}</h4></div>

                <div class="middle" v-if="product.title != undefined"><h4 class="name">{{product.name}} - {{product.title}}</h4></div>  


              <!-- old price example: 
              <div class="product_price pt-3"><button itemprop="price">₪60.00</button>
              <span class="old-price">₪80.00</span></div>
               -->
               <div class="stickToBottom">
              <div class="product_price pt-2">
                <button v-if="product.price_to_show !== undefined" itemprop="price">החל מ-  ₪{{product.price_to_show}} </button>
                <button v-if="product.price_to_show === undefined"> לפרטים </button>
                </div>
              </div>
          </div>
          </div>
          </div>
          </router-link>

      </div>
    </div>
  </div>

</div>
</div>
</div>
</template>
<script>

import { mapActions, mapState } from 'vuex'
export default {
    data() {
    return { 
      showImageMenu:false ,
      cols: 3,
      pageTitle: 'קטלוג',
      }
    },
    
    methods: {
    ...mapActions(['initFilteredArray', 'setIsSearchFilter']
    ),
    getMainCatalogPath() {
      return "/קטלוג/" + this.$route.params.mainCategory;
    },

    changePageTitle(title) {
      this.pageTitle=title;
    },

    filterCatalog() {
        this.changePageTitle('קטלוג');
        this.initFilteredArray(this.products);
        document.title = 'קטלוג | וילונות מעצבים חללים'
    },
   
    filterShort() {
        this.changePageTitle('וילונות קצרים');
        this.initFilteredArray(this.products.filter(product => this.isProductByString(product, "short")));
        document.title = 'וילונות קצרים | וילונות מעצבים חללים'
    },
    filterLong() {
        this.changePageTitle('וילונות ארוכים');
        this.initFilteredArray(this.products.filter(product => this.isProductByString(product, "long")));
        document.title = 'וילונות ארוכים | וילונות מעצבים חללים'
    },
    filterCurtains() {
        this.changePageTitle('וילונות');
        this.initFilteredArray(this.products.filter(product => this.isProductByString(product, "curtain")));
        document.title = 'וילונות | וילונות מעצבים חללים'
    },
    filterPillow() {
      this.changePageTitle('כריות נוי');
      this.initFilteredArray(this.products.filter(product => this.isProductByString(product, "pillow")));
      document.title = 'כריות נוי | וילונות מעצבים חללים';
    },
    filterCarpet() {
      this.changePageTitle('שטיחים');
      this.initFilteredArray(this.products.filter(product => this.isProductByString(product, "carpet")));
      document.title = 'שטיחים | וילונות מעצבים חללים';
    },
    filterSet() {
      this.changePageTitle('סט 4 חלקים');
      this.initFilteredArray(this.products.filter(product => this.isProductByString(product, "set")));
      document.title = 'סט וילונות 4 חלקים | וילונות מעצבים חללים';
    },
    filterSunProof() {
      this.changePageTitle('וילונות אטומים');
      this.initFilteredArray(this.products.filter(product => this.isProductByString(product, "sunproof")));
      document.title = 'וילונות אטומים | וילונות מעצבים חללים';
    },
    filterBedroom() {
      this.changePageTitle('וילונות לחדר שינה');
      this.initFilteredArray(this.products.filter(product => this.isProductByString(product, "short")));
      document.title = 'וילונות לחדר שינה | וילונות מעצבים חללים';
    },
    filterCheap() {
      this.changePageTitle('וילונות לבית בזול');
      this.initFilteredArray(this.products.filter(product => this.isProductByString(product, "cheap")));
      document.title = 'וילונות לבית בזול | וילונות מעצבים חללים';
    },
    filterRods() {
      this.changePageTitle('מוטות');
      this.initFilteredArray(this.products.filter(product => this.isProductByString(product, "rod")));
      document.title = 'מוטות | וילונות מעצבים חללים';
    },

    filterMaps() {
      this.changePageTitle('מפות');
      this.initFilteredArray(this.products.filter(product => this.isProductByString(product, "map")));
      document.title = 'מפות | וילונות מעצבים חללים';
    },

    filterHadom() {
      this.changePageTitle('הדומים');
      this.initFilteredArray(this.products.filter(product => this.isProductByString(product, "hadom")));
      document.title = 'הדומים | וילונות מעצבים חללים';
    },

    filterPaldim() {
      this.changePageTitle('וילונות פלדים על מסילה');
      this.initFilteredArray(this.products.filter(product => this.isProductByString(product, "paldim")));
      document.title = 'וילונות פלדים | וילונות מעצבים חללים';
    },

    getToLowerCaseArray(array) {
      var arr = array.map(v => v.toString().toLowerCase());
      return arr;
    },
     isProductByString(product, type){
        return this.getToLowerCaseArray(product.properties).includes(type);
     },

      imageMenuClicked() {
       this.showImageMenu=(!this.showImageMenu)
      },
      showMenuForLargeScreen() {
        if (window.innerWidth > 1160) {
          this.showImageMenu=true;
        }
      },
      scrollToTop() {
        window.scrollTo(0,0);
      },
      getProductCategoryForUrl(product) {
        
        if(this.isProductByString(product, "curtain"))
          return "וילונות";
        
        if(this.isProductByString(product, "pillow"))
          return "כריות";

        if(this.isProductByString(product, "carpet"))
          return "שטיחים";
        
        if(this.isProductByString(product, "rod"))
          return "מוטות";

        if(this.isProductByString(product, "map"))
          return "מפות";

        if(this.isProductByString(product, "hadom"))
          return "הדומים";
      },
      checkIfCategory() {
        if(this.$route.path === "/קטלוג") {
          if(this.isSearchFilter)
            this.setIsSearchFilter(false);
          else
            this.filterCatalog();
        } else {

        let category = this.$route.params.subCategory ? this.$route.params.subCategory : this.$route.params.mainCategory;
        if(category)
        {
          this.scrollToTop();
          switch(category) {
            case "וילונות-קצרים":
              this.filterShort();
              break;
            case "וילונות-ארוכים":
              this.filterLong();
              break;
            case "וילונות-אטומים":
              this.filterSunProof();
              break;
            case "סט-4-חלקים":
              this.filterSet();
              break;
            case "שטיחים":
              this.filterCarpet();
              break;
            case "כריות":
              this.filterPillow();
              break;
            case "וילונות-לבית-בזול":
              this.filterCheap();
              break;
            case "וילונות-לחדר-שינה":
              this.filterBedroom();
              break;
            case "וילונות":
              this.filterCurtains();
              break;
            case "מוטות":
              this.filterRods();
              break;
            case "מפות":
              this.filterMaps();
              break;
            case "הדומים":
              this.filterHadom();
              break;
            case "וילונות-פלדים-על-מסילה":
              this.filterPaldim();
              break;
              
            default:
              this.$router.push({ name: 'product', params: {category: this.$route.params.mainCategory, name: category } }).catch(err => {err});
              break;
        }
        }
        }
      },
      pushCategory() {
        this.imageMenuClicked();
        this.showMenuForLargeScreen();
      }
  },
  created() {
    this.scrollToTop(); 
  },
    computed: {
    rows () {
      let rows = [];
      let numberOfRows = Math.ceil(this.products.length / this.cols);

      for (let i = 0; i < numberOfRows; i++) {
        rows.push(this.products.slice(i * this.cols, i * this.cols + this.cols ))
      }
      return rows;
    } ,

    ...mapState(['filteredArray', 'products', 'isSearchFilter'])  
  },

 mounted () {
    this.showMenuForLargeScreen();
    this.checkIfCategory();
  },
  watch:{
    $route (){
        this.checkIfCategory();
    }
  } 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.collapse-content .fa.fa-heart:hover {
  color: #f44336 !important;
}
.collapse-content .fa.fa-share-alt:hover {
  color: #0d47a1 !important;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}
p {
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}
.wrapper {
    display: flex;
    width: 100%;
}

body {
  overflow-x: hidden;
  font-size: 16px;
  
}

@media only screen and (max-width: 985px) {
.cardText{
  font-size: calc(0.5em + 0.5vw);
}
}

@media only screen and (max-width: 725px) {
.cardText{
  font-size: calc(0.4em + 0.4vw);
}
}

@media only screen and (max-width: 620px) {
.cardText{
  font-size: calc(0.4em + 0.3vw);
}
}

@media only screen and (max-width: 400px) {
.cardText{
  font-size: 0.65rem;
}
}

@media only screen and (max-width: 314px) {
.cardText{
  font-size: 0.55rem;
}
}

@media only screen and (min-width: 985px) {
.cardText{
  font-size: calc(0.5em + 0.7vw);
}
}
@media only screen and (max-width: 985px) {
#viewport {
  padding-right: 3%;
  padding-left: 3%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding-top: 3%;
  overflow: auto;
  }
}

@media only screen and (min-width: 985px) {
#viewport {
  padding-right: 8%;
  padding-left: 8%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding-top: 3%;
  overflow: auto;
  }
}

@media only screen and (min-width: 1400px) {
#viewport {
  padding-right: 15%;
  padding-left: 15%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding-top: 3%;
  overflow: auto;
  }
}

@media only screen and (max-width: 985px) {
#content {
  padding-top: 10%;
  padding-bottom: 15%;
  overflow: hidden;
}
}

#content {
  padding-right: 0%;
  padding-left: 0%;
  padding-bottom: 5%;
  overflow: hidden;
  /* display: flex; */
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  justify-content: center;
}

.hrTop {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid rgb(63, 0, 0);
    padding: 0;
    margin-top: 15px;
}

.knockout {
  background: url("../assets/coma_misti.jpg") -10px -20px;
  color: red;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: bold;
  font-size: calc(2em + 3vw);
  width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
  text-align: center;
  margin: auto;
}

@media only screen and (max-width: 985px) {
.catalog {
  margin-top: 8%;
}
}

body{
  background: rgb(5, 86, 153);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgb(8, 8, 8);
}

.menuCard:hover .menuOverlay {
  opacity: 0.5;
}

.mycard:hover .overlay {
  opacity: 0.5;
}
.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.menuOverlay {
   border-radius: 50%;
  width: 93px;
  height: 93px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: .5s ease;
  background-color: rgb(8, 8, 8);
}
.colors{
margin-top: -10px;
  color: #002347e0;

}

#test {
    /* background-image: url("../../img/back9.jpg"); */
      /* background: linear-gradient(
    115deg,
    rgba(13, 102, 154, 0.92) 10%,
    rgb(26, 141, 208) 90%
  ); */
  background: rgb(255, 255, 255);
    min-height: 100%; 
}

.ProductCard-price--daySaleDetails, .ProductCard-price--openBox, .ProductCard-price--sale {
    color: #c91f24;
}
.ProductCard-price {
    display: inline-block;
    color: #221924;
    text-decoration: none;
    margin-right: 4px;
    font-size: 1.25rem;
    line-height: 1.2;
}

.ProductCard-price .from_text {
    font-weight: 400;
}

.ProductCard-price.ProductCard-price--listPrice, .ProductCard-price.ProductCard-price--listPriceLabel, .ProductCard-price.ProductCard-price--perItem, .ProductCard-price.ProductCard-price--perSpecialUnit, .ProductCard-price.ProductCard-price .ca .ProductCard-price--list-price, .ProductCard-price.ProductCard-price .from_text, .ProductCard-price.ProductCard-price .price_prefix {
    font-size: 1rem;
}
.rounded {
   border-radius: 25px;

}
.menuCard {
  border-radius: 50%;
  width: 93px;
  height: 93px;
  align-items: center;
  display: flex;
}  
.ProductCard {
    display: inline-block;
    padding: 8px;
    font-size: 1rem;
    text-decoration: none;
    -ms-flex: 1 auto;
    flex: 1 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 3px;
     width: 50%;
}

@media screen and (max-width: 1160px) {
.top-menu {
  flex-flow: row wrap;
    justify-content: center;
    overflow: hidden;
    /* max-width: 100%; */
    display: flex;
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 10%;
}
}

@media screen and (min-width: 985px) {
.top-menu {
  flex-flow: row wrap;
    justify-content: center;
    overflow: hidden;
    /* max-width: 100%; */
    display: flex;
    padding-top: 5%;
}
}
@media screen and (max-width: 985px) {
.top-menu {
  flex-flow: row wrap;
    justify-content: center;
    overflow: hidden;
    /* max-width: 100%; */
    display: flex;
    padding-top: 5%;
}
}

@media screen and (max-width: 500px) {
.top-menu {
  flex-flow: row wrap;
    justify-content: center;
    overflow: hidden;
    /* max-width: 100%; */
    display: flex;
    padding-right: 0%;
    padding-left: 0%;
    padding-top: 5%;
}
}

@media screen and (min-width: 64rem){
.menu-text{
    font: 300 16px/1.43 Montserrat,Helvetica,Arial,sans-serif;
}
}

.menu-textmenu-text{
    margin-top: .5rem;
    font: 300 14px/1.43 Montserrat,Helvetica,Arial,sans-serif;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 .3rem;
}
.notFound {
  margin-top: 10%;
  padding-bottom: 10%;
}

@media screen and (min-width: 1160px) {
.isImageMenu {
  display: none !important;
}
}
.isImageMenu {
  margin-top: 11%;
}
@media screen and (max-width: 700px) {
.isImageMenu {
  margin-top: 16%;
}
}

@media screen and (min-width: 985px) {
.productPrice {
    font-size: 1.1rem;
}
}

@media screen and (max-width: 985px) {
.productPrice {
    font-size: 1rem;
}
}

@media screen and (max-width: 500px) {
.productPrice {
    font-size: 0.75rem;
}
}

.name {
  color:  #2c2c2b;
  -webkit-background-clip: text;
  font-weight: bold;
  font-size: calc(0.6em + 0.6vw);
  margin: 0px auto;
  text-align: center;
  margin-bottom: 5px;
}

.ProductCard-pricing{
  font-weight: bold;
  font-size: calc(0.5em + 0.8vw);
  width: 80%;
  margin: 0 auto;
  color: #053669;
}
.middle {
  font-size: calc(0.8em + 0.2vw);
}
@media screen and (min-width: 700px) {
.middle {
  padding-top: 2%;
  padding-bottom: 2%;
}
}
img {
    opacity: 1;
    transition: opacity 1s ease;
}

.lazy {
    opacity: 1;
    /* height: 300px;
    width: 220px; */
}


/* ----------------------------------------- NEW --------------------------------------------- */
.m-b-20 {margin-bottom: 20px;}
.wrap-pic-w img {width: 100%;}
.wrap-pic-max-w img {max-width: 100%;}
.of-hidden {overflow: hidden;}
.pos-relative {position: relative;}

.block2-labelnew::before {
  background-color: #66a8a6;
  content: 'New';
}
.block2-labelsale::before {
  background-color: #e65540;
  content: 'Sale';
}
.block2-labelsale::before,
.block2-labelnew::before 
{
  z-index: 100;
  font-size: 12px;
  color: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 22px;
  border-radius: 11px;
  position: absolute;
  top: 12px;
  left: 12px;
}
.block2-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.3);
  opacity: 0;
}
.block2-overlay:hover {
  opacity: 1;
}

.block2-overlay:hover .block2-btn-addcart {
  bottom: 20px;
}

.block2-overlay:hover 
.block2-overlay:hover{
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.trans-0-4 {
	-webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}
.block2-btn-towishlist,
.block2-btn-addwishlist {
  display: block;
  position: absolute;
  top: 26px;
  right: 20px;
  font-size: 20px;
  color: white;
  line-height: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.block2-btn-addwishlist:hover {
  color: white;
}

.block2-btn-addwishlist .icon-wishlist,
.block2-btn-towishlist .icon-wishlist {
  line-height: 0;
}

.block2-btn-addwishlist:hover .icon_heart_alt {
  display: none;
}

.block2-btn-addwishlist:hover .icon_heart {
  display: block;
}
.block2-overlay:hover .block2-btn-addwishlist,
.block2-overlay:hover .block2-btn-towishlist{
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.hov-pointer:hover {cursor: pointer;}
.block2-btn-addcart {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -45px;
}
.block2-overlay:hover .block2-btn-addcart {
  bottom: 20px;
}
.w-size1 {
	width: 161px;
}
.flex-c-m {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
}
.bo-rad-23 {
  border-radius: 23px; 
  outline: none;
  }
.m-text3 {
	font-size: 16px;
	color: white;
	text-transform: uppercase;
}
.s-text1 {
	font-size: 15px;
	color: white;
	text-transform: uppercase;
}
.p-t-20 {padding-top: 20px;}
.dis-block {display: block;}
.s-text3 {
	font-family: Montserrat-Regular;
	font-size: 15px;
	color: #555555;
	line-height: 1.5;
}
.p-b-5 {padding-bottom: 5px;}
.m-text6 {
	font-family: Montserrat-Regular;
	font-size: 16px;
	color: #555555;
	text-transform: uppercase;
}
.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}
.p-t-35 {padding-top: 35px;}

.cardWidth {
  width: 50%;
  padding: 2%;
}

@media only screen and (min-width: 985px) {
.cardWidth {
  padding: 0.5%;
}
}

.m-text7 {
	font-family: Montserrat-Regular;
	font-size: 16px;
	color: #555555;
	text-transform: uppercase;
	text-decoration: line-through;
}

.m-text8 {
	font-family: Montserrat-Regular;
	font-size: 16px;
	color: #e65540;
	text-transform: uppercase;
}
.p-r-5 {padding-right: 5px;}
.size1 {
	width: 100%;
	height: 40px;
  border: none;
}
.cardDesign {
  box-shadow: 0 0 6px 2px #ececec;
  border: 0px !important;
  border-radius: 3px;
  height: 120%;
}
::v-deep .card-body { 
  padding: 0.7rem !important;
}
.product_price button:after {
  background: #dc9c63;
  color: black;
  width: 13px;
  height: 100%;
  position: absolute;
  transform: skew(-10deg);
  content: "";
  left: -5px;
  top: 0;
  z-index: -1;
}
.product_price button {
  background: #dc9c63;
  color: black;
  border: none;
  padding: 10px 15px 10px 10px;
  font-weight: 600;
  font-size: calc(0.5em + 0.7vw);
  border-radius: 0 4px 4px 0;
  position: relative;
  margin-left: 10px;
  display: table-cell;
  z-index: 1;
  outline: 0;
}
.old-price  {
  color: #aaa;
  font-size: 15px;
  margin-left: 1px;
  text-decoration: line-through;
}

@media only screen and (min-width: 1600px) {
  #container {
  position: relative;
  min-height: 190px;
}
}

@media only screen and (max-width: 1600px) {
  #container {
  position: relative;
  min-height: 160px;
}
}

@media only screen and (max-width: 1000px) {
  #container {
  position: relative;
  min-height: 130px;
}
}
@media only screen and (max-width: 750px) {
  #container {
  position: relative;
  min-height: 110px;
}
}
.stickToBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.linkSection{
  float: right;
  position: absolute;
  padding-right: 2%;
}

@media screen and (max-width: 1160px) {
 .linkSection{
   padding-top: 4%;
 }
}
 .linkColor {
   color: #363636;
 }
</style>
