<template>
<div id="product" dir="rtl">


<div class="linkSection linkColor">
  <router-link class="linkColor" to="/">בית</router-link> / 
  <router-link class="linkColor" to="/קטלוג">קטלוג</router-link> / 
  <router-link class="linkColor" :to="this.category_path" >{{this.$route.params.category}}</router-link>  / 
  <span>{{this.currentProduct.name}}</span>  
</div>

<div class="nameTitle">
<header>
  <div class="title">
  <div class="knockout">{{this.currentProduct.name}}</div>
  <hr/>
  </div>
</header>
</div>

<div class="d-md-flex h-md-100 pagePadding">

<!-- First Half -->

<div class="col-md-6 p-0 h-md-100">
    <div class="text-white d-md-flex pt-2 h-100 text-center justify-content-center">
  <div class="mycarousel">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#fffff"
      style="text-shadow: 1px 1px 2px #333;"
    >
    
    <div v-viewer="{title: false, toolbar: false}">
      <b-carousel-slide v-for="(item, index) in currentProduct.images" :key="index" :index="index">
        <template v-slot:img>
            <img
              class="slideImg"
              width="1024"
              height="480"
              :src="item"
              :alt="currentProduct.name"
            >
        </template>
      </b-carousel-slide> 
    </div>
  </b-carousel>
</div>
    </div>
</div>

<!-- Second Half -->

<div class="col-md-6 p-0 h-md-100 loginarea">
    <div class="d-md-flex align-items-center h-md-100 p-1 justify-content-center">
<div class="container2">

<h5 class="topname" v-if="this.currentProduct.description">{{this.currentProduct.description}}</h5>
<h5 class="topname" v-if="!this.currentProduct.description && this.currentProduct.title">{{this.currentProduct.title}}</h5>
<h5 class="topname" v-if="!this.currentProduct.description && !this.currentProduct.title">{{this.currentProduct.name}}</h5>

<div class="top1">
  <div class="pt-2 pb-2 details" style="text-align:center"  v-if="getToLowerCaseArray(currentProduct.properties).includes('out_of_stock')">
  <b>המוצר אזל זמנית מהמלאי</b></div>

<table v-if="currentProduct.pricesAndSizes" class="table table-borderless table-hover priceTable top1">
  <thead>
    <tr>
      <th scope="col" class="descr headColor">תיאור</th>
      <th scope="col" class="priceCol headColor">מחיר</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item, index) in currentProduct.pricesAndSizes" :key="index" :index="index">
      <td>
        <div class="radio">
          <label class="txt pointer"><input type="radio" v-model="selectedSize" :name="item.desc" 
          :value="index" :id="index" @click="checkIfValidColor(index)" class="radiobtn"> {{item.desc}}  </label>
        </div>
      </td>

      <td><span class="txt">{{item.price}} <span v-if="!getToLowerCaseArray(currentProduct.properties).includes('no_price')">ש"ח</span> </span></td>
    </tr>

  </tbody>
</table>

 
<div class="pt-2 pb-2 details" style="text-align:center" v-if="!currentProduct.pricesAndSizes && 
!getToLowerCaseArray(currentProduct.properties).includes('out_of_stock')"><b>לפרטים חייגו - 058-551-5533</b></div>
</div>

<div id="text">
<div class="float-right pr-3" v-if="this.activeBtnColor === undefined"><b>בחר צבע: </b></div>
<div class="float-right pr-3" v-if="this.activeBtnColor != undefined"><b>צבע : {{ this.activeBtnColor}}</b></div>
</div>

<div class="product-color">

  <div class="color-choose">
    <div v-for="color in this.currentProduct.colors" :key="color" 
    :class="{colorchooseselected: activeBtnColor === color }">

    <span v-if="colorsArray.includes(color)" class="hvr-grow colorSection">
      <input data-image="color" type="radio" :id="color" :name="color" :value="color" @click="setActiveColor(color)">
      <label class="colorLabel" for="" @click="setActiveColor(color)"><span></span></label>
      <label class="colorText">{{color}}</label>
    </span>
    
    <span v-if="!colorsArray.includes(color)" class="disabledColor colorSection">
      <input data-image="color" type="radio" :id="color" :name="color" :value="color" disabled>
      <label class="colorLabel" for=""><span></span></label>
      <label class="colorText lowOpacity">{{color}}</label>
    </span>
    
    </div>
    
  </div>
</div>

  <div class="quantity" v-if="!getToLowerCaseArray(currentProduct.properties).includes('out_of_stock') && 
                                                            !getToLowerCaseArray(currentProduct.properties).includes('no_price')">
      <button class="plus-btn btn1" type="button" name="button" @click="increment()">
        <font-awesome-icon icon="plus"/>
      </button>
      <input type="text" name="name" :value="quantity" readonly>
      <button class="minus-btn btn1" type="button" name="button" @click="decrement()">
        <font-awesome-icon icon="minus"/>
      </button>

 <b-button pill class="btn btn-dark hvr-pop mr-4"
    @click="addItemToCart()" type="button"><b>הוסף לעגלה <font-awesome-icon icon="shopping-cart"/></b></b-button>
 </div>
  </div>
</div>
<div>
<hr class="pb-3 mt-3">
  <div class="safe">
    <div><font-awesome-icon icon="check-circle"/> קנייה מהירה ומאובטחת בפייפאל, כרטיס אשראי או ביט</div>
    <div><font-awesome-icon icon="check-circle"/> משלוחים מהירים <font-awesome-icon icon="shipping-fast"/></div>
    <div><font-awesome-icon icon="check-circle"/> החזרת מוצרים עד 14 יום!</div>
  </div>
<img class="mt-4 secure" src="../assets/secure2.png" alt="תשלום מאובטח">
    </div>
    </div>
</div>
</div>
</template>



<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return { 
       slide: 0,
       sliding: null,
       selectedSize: undefined,
       selectedSizeColorsArray: [],
       activeBtnColor: undefined,
       quantity: 1,
       currentProduct: undefined,
       category_path: "/קטלוג/" + this.$route.params.category
      }
    },

  computed: {
    ...mapState(['cart', 'products']),
    colorsArray: function () {
        return this.getSpecificColors()
      }
  },
  methods: {
    ...mapActions(['addToCart', 'changeQuantity']
    ),
   scrollToTop() {
        window.scrollTo(0,0);
   },
   getColorString(array) {
     var string = "";

     for (var i=0; i< array.length -1; i++) {
        string += array[i] + ", "
     }
     string += array[i];
     
     return string;
   },
    getToLowerCaseArray(array) {
      var arr = array.map(v => v.toString().toLowerCase());
      return arr;
    },
    goToCatalogPage() {
      this.$router.push('/').catch(err => {err})  
    },
    checkIfShort(product) {
      return this.getToLowerCaseArray(product.properties).includes("short");
    },
    checkIfLong(product) {
      return this.getToLowerCaseArray(product.properties).includes("long");
    },
    checkIfPillow(product) {
        return this.getToLowerCaseArray(product.properties).includes("pillow");
    },
    checkIfCarpet(product) {
        return this.getToLowerCaseArray(product.properties).includes("carpet");
    },

    isProductByString(product, type){
      return this.getToLowerCaseArray(product.properties).includes(type);
    },
    getSpecificColors() {
      if(this.selectedSize === undefined) {
        return this.currentProduct.colors;
      } else {
        this.selectedSizeColorsArray = [];

        for (let index = 0; index < this.currentProduct.colors.length; index++) {
            if(!this.currentProduct.pricesAndSizes[this.selectedSize].no_colors.
            includes(this.currentProduct.colors[index]))
              this.selectedSizeColorsArray.push(this.currentProduct.colors[index]);
        }
        return this.selectedSizeColorsArray;
      }
    },
    setActiveColor(color) {
      this.activeBtnColor = color;
    },
     increment () {
       if(this.quantity < 10)
         this.quantity++
    },
    decrement () {
      if(this.quantity != 1) {
        this.quantity--
      }
    },
    addItemToCart() {
      if(this.selectedSize === undefined) {
        this.$toasted.show("יש לבחור גודל רצוי!&nbsp;&nbsp;&nbsp;&nbsp;", { 
            type: 'error',
            theme: "bubble", 
            position: "bottom-right", 
            duration : 2000,
          });
      } else if (this.activeBtnColor === undefined) {
        this.$toasted.show("יש לבחור צבע רצוי!&nbsp;&nbsp;&nbsp;&nbsp;", { 
          type: 'error',
          theme: "bubble", 
          position: "bottom-right", 
          duration : 2000
          });
      } else {
        let foundSame = false;

        let _name= this.currentProduct.name;
        let _title = this.currentProduct.title;
        let _image = this.currentProduct.images[0];
        let _color = this.activeBtnColor;
        let _size= this.currentProduct.pricesAndSizes[this.selectedSize].desc;
        let _price = this.currentProduct.pricesAndSizes[this.selectedSize].price;
        let _quantity = this.quantity;



          this.cart.forEach(function (value, i) {
            if(_name === value.name &&
              value.size === _size &&
              value.color === _color &&
              value.price === _price) {
                this.changeQuantity({'index': i, 'newQuantity': (value.quantity + _quantity)});
                foundSame = true;
              }
          }, this);

        if(!foundSame) {
          this.addToCart({
                          'name' : _name,
                          'title' : _title,
                          'image' : _image,
                          'color' : _color,
                          'size' : _size,
                          'price' : _price,
                          'quantity' : _quantity
          })
        }
        
        this.$swal.fire({
          title: _name + ' התווסף לעגלה שלך!',
          icon: 'success',
          showCancelButton: true,
          confirmButtonText: 'עבור לעגלה',
          cancelButtonText: 'המשך בקניה',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.$router.push('/סל-קניות').catch(err => {err});
          }
        })

      }
    },
    findCurrentProduct(name) {
      name = name.replace(/-/g," ");
      for (let i = 0; i < this.products.length; i++) {
        if(this.products[i].name === name)
          this.currentProduct = this.products[i];
      }
    },
    getSizeColors(selected) {
      this.selectedSizeColorsArray = [];

        for (let index = 0; index < this.currentProduct.colors.length; index++) {
            if(!this.currentProduct.pricesAndSizes[selected].no_colors.
            includes(this.currentProduct.colors[index]))
              this.selectedSizeColorsArray.push(this.currentProduct.colors[index]);
        }
        return this.selectedSizeColorsArray;
    },

    checkIfValidColor(index) {
        if (!this.getSizeColors(index).includes(this.activeBtnColor)) {
          this.activeBtnColor = undefined;
        }
      },
      initProductAndTitle() {
        this.findCurrentProduct(this.$route.params.name);
        if(this.currentProduct.title)
          document.title = this.currentProduct.name + " - " + this.currentProduct.title + ' | וילונות מעצבים חללים';
        else
          document.title = this.currentProduct.name + ' | וילונות מעצבים חללים';
      }
    },
 created() {
  this.initProductAndTitle();
  this.scrollToTop();
 },
 watch:{
    $route (){
      this.initProductAndTitle();
    }
  } 
}
</script>


<style scoped>
#product {
  background: white;
  padding-top: 20px; 
  padding-bottom: 4%; 
}
.mycarousel{
  width: 100%;
  padding-right: 5%;
  padding-left: 5%;
}

@media only screen and (max-width: 500px) {
.mycarousel{
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
}
}

.slideImg {
  width:100%;
  height: 450px !important;
  object-fit:scale-down;
  -webkit-transition: all 1s ease; /* Safari and Chrome */
  -moz-transition: all 1s ease; /* Firefox */
  -ms-transition: all 1s ease; /* IE 9 */
  -o-transition: all 1s ease; /* Opera */
  transition: all 1s ease;
  cursor: pointer;
}

@media only screen and (min-width: 1400px) {
  .slideImg {
  height: 650px !important;
}
}

@media only screen and (min-width: 1800px) {
  .slideImg {
  height: 700px !important;
}
}

::v-deep .carousel-control-next,
::v-deep .carousel-control-prev {
  filter: invert(100%);
}

::v-deep .carousel-indicators {
   color: #0c0c0c;
  margin-right: 7%;
}

@media only screen and (min-width: 1400px) {
::v-deep .carousel-indicators {
  margin-right: 10%;
}
}

::v-deep .carousel-indicators li {
  filter: invert(100%);
}
@media only screen and (min-width: 985px) {
.knockout {
  background: url("../assets/coma_misti.jpg") -80px -80px;
  color: red;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: bold;
  font-size: calc(2em + 1.5vw);
  width: 100%;
  margin: 0px auto;
  text-align: center;
  padding-top: 1%; 
}
}

@media only screen and (max-width: 985px) {
.knockout {
  background: url("../assets/coma_misti.jpg") -80px -80px;
  color: red;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: bold;
  font-size: calc(1.5em + 1.5vw);
  width: 100%;
  padding-top: 2%;
  padding-right: 3%;
  padding-left: 3%;
  margin: 10px auto;
}
}

.description {
  margin-top: 2%;
  background-image: url("../../img/back9.jpg");
}
.topname {
  color: #d48457;
  font-weight: bold;
  font-size: calc(1em + 1vw);
}

.btn1 {
  background-color: #E1E8EE;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}
.minus-btn {
  margin-bottom: 3px;
}
.plus-btn {
  margin-top: 2px;
}
.quantity {
  padding-top: 20px;
  /* margin-right: 50px; */
}
.quantity input {
  -webkit-appearance: none;
  border: none;
  text-align: center;
  width: 32px;
  font-size: 16px;
  color: #43484D;
  font-weight: 300;
}
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover{
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media only screen and (max-width: 985px) {
.mybtn{
    margin-right: 2%;
    margin-top: 8%;
  }
}
@media only screen and (min-width: 985px) {
.mybtn{
    margin-right: 2%;
    margin-top: 2%;
  }
}
.top1 {
  text-align: right;
  margin-top: 10%;
}
.priceTable {
  width: 100%;
  padding-top: 60px;
  direction: rtl;

}.txt {
  font-size: calc(0.5em + 0.5vw);
  color: #2f3132;
  font-weight: bolder;
}
.pointer {
  cursor: pointer;
}
.container2{
  padding: 5px;
  width: 100%;
}
.priceCol {
  width: 20%;
}
.radio {
  float: right;
  text-align: right;
}
button[type="button"]:disabled {
  background: #ccc;
}
.activeBtn{
  border-color:rgb(0, 41, 44);
}
.product-color span,
.cable-config span {
  font-size: 14px;
  font-weight: 400;
  color: #86939E;
  display: inline-block;
}

/* Product Color */
.product-color {
  margin-bottom: 10px;
  padding-top: 7%;
}
@media only screen and (max-width: 410px) {

.product-color {
  margin-bottom: 10px;
  padding-top: 8%;
}

}
.color-choose div {
  display: inline-block;
  text-align: right;
}

.color-choose input[type="radio"] {
  display: none;
}

.color-choose input[type="radio"] + label span {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
}

.color-choose input[type="radio"] + label span {
  border: 1px solid rgba(49, 49, 49, 0.589);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.33);
}

.colorchooseselected input[type="radio"] + label span {
  border: 3px solid #006c8d;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.33);
}

.color-choose input[type="radio"]#אדום + label span {
  background-color: #C91524;
}
.color-choose input[type="radio"]#טורקיז + label span {
  background-color: #035361;
}
.color-choose input[type="radio"]#שחור + label span {
  background-color: #1b1b1b;
}
.color-choose input[type="radio"]#אופוויט + label span {
  background-color: #d8d0d0;
}
.color-choose input[type="radio"]#אופוויט-חלק + label span {
  background-color: #d8d0d0;
}
.color-choose input[type="radio"]#אופוויט-אפור + label span {
  background: linear-gradient(
    195deg,
    #747474,
    #d8d0d0 70%
  );
}
.color-choose input[type="radio"]#אופוויט-מוקה + label span {
  background: linear-gradient(
    195deg,
    #a38068,
    #d8d0d0 70%
  );
}
.color-choose input[type="radio"]#אופוויט-בורדו + label span {
  background: linear-gradient(
    195deg,
    #690505,
    #d8d0d0 70%
  );
}
.color-choose input[type="radio"]#אפור + label span {
  background-color: #747474;
}
.color-choose input[type="radio"]#לבן + label span {
  background-color: #ffffff;
}
.color-choose input[type="radio"]#לבן-חלק + label span {
  background-color: #ffffff;
}
.color-choose input[type="radio"]#בז + label span {
  background-color: #f5f5dc;
}

.color-choose input[type="radio"]#בורדו + label span {
  background-color: #690505;
}

.color-choose input[type="radio"]#מוקה + label span {
  background-color: #a38068;
} 

.color-choose input[type="radio"]#שחור-אפור + label span {
  background: linear-gradient(
    165deg,
    #0c0c0c ,
    #8b8b8b 70%
  );
} 

.color-choose input[type="radio"]#בורדו-שחור + label span {
  background: linear-gradient(
    195deg,
    #690505,
    #1b1b1b 70%
  );
} 

.color-choose input[type="radio"]#אפור-תכלת + label span {
  background: linear-gradient(
    125deg,
    #348fc4,
    #6e6969 90%
  );
} 

.color-choose input[type="radio"]#אבן + label span {
  background-color: #d1c08a;
}

.color-choose input[type="radio"]#ורוד + label span {
  background-color: #b82c99;
}

.color-choose input[type="radio"]#סגול + label span {
  background-color: #af3bdd;
}

.color-choose input[type="radio"]#חום + label span {
  background-color: #5e321d;
}

.color-choose input[type="radio"]#כחול + label span {
  background-color: #153a80;
}

.color-choose input[type="radio"]#ירוק + label span {
  background-color: #1c9927;
}

.color-choose input[type="radio"]#תכלת-טורקיז + label span {
  background-color: #0995ad;
}

.color-choose input[type="radio"]#תכלת + label span {
  background-color: #0e92c6;
}

.color-choose input[type="radio"]#טבעי-08 + label span {
  background-color: #bb600ac7;
}

.color-choose input[type="radio"]#חום-אופוויט + label span {
  background: linear-gradient(
    125deg,
    #722a15,
    #b69c74 90%
  );
}

.color-choose input[type="radio"]#אפור-צהוב + label span {
  background: linear-gradient(
    125deg,
    #5f5c5b,
    #e7b428 120%
  );
}

.color-choose input[type="radio"]#אפור-בורדו + label span {
  background: linear-gradient(
    125deg,
    #5f5c5b,
    #6d0404 120%
  );
}
.color-choose input[type="radio"]#אפור-שחור + label span {
  background: linear-gradient(
    125deg,
    #5f5c5b,
    #080808 140%
  );
}
.color-choose input[type="radio"]#שמנת + label span {
  background-color: #d0d5d6;
}

.color-choose input[type="radio"]#ורוד-עתיק + label span {
  background-color: #d87ea3a9;
}

.color-choose input[type="radio"]#קפה + label span {
  background-color: #b19e85;
}

.color-choose input[type="radio"]#חרדל + label span {
  background-color: #c5ba54;
}


.disabledColor  input[type="radio"] + label span {
  opacity: 0.2;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.33);
}
.color-choose input[type="radio"]:checked + label span {
  /* background-image: url(images/check-icn.svg); */
  background-repeat: no-repeat;
  background-position: center;
}
.descr {
  padding-right: 4%;
}

/* Pop */
@-webkit-keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.hvr-pop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pop:active {
  -webkit-animation-name: hvr-pop;
  animation-name: hvr-pop;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
#text{
  margin-top: 3%;
  padding-bottom: 3%;
}
.toasted-container .toasted > svg {
  /* margin-right: .5rem; */
  padding-right: 2px;
  padding-left: 2px;
  margin-right: 2px;
  margin-left: 2px;
  /* margin-left: -.4rem; */
}
.headColor {
  color: #2f3132;
  font-weight: bolder ;
}
@media only screen and (max-width: 410px) {
.pagePadding {
  padding-top: 0px;
}
}
@media only screen and (min-width: 410px) {
.pagePadding {
  padding-top: 5px;
}
}

.details {
  font-size: calc(0.8em + 0.7vw);
}

.safe {
  color:#bd7045;
  font-size: calc(0.6em + 0.9vw);
  text-align: right;
  margin-right: 5%;
  margin-top: 5%;
  padding-right: 2%;
  padding-left: 2%;
 }

 .secure {
    width: 50%;
    max-width: 100%;
    padding-right: 5%;
    padding-left: 5%;
    margin-bottom: 1%;
 }

 @media only screen and (max-width: 1400px) {
    .secure {
    width: 60%;
  }
 }

  @media only screen and (max-width: 1800px) {
    .secure {
    width: 50%;
  }
 }

  @media only screen and (min-width: 1800px) {
    .secure {
    width: 40%;
  }
 }

 .colorSection{
   vertical-align: top;
    display: inline-block;
    margin-right: 8px;
 }

 .colorLabel {
   display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
 }

 .colorText {
    display: block;
    text-align: center;
    margin-top: -5px;
    color: #2e2d2dfa;
 }
 .lowOpacity {
   opacity: 0.4;
 }
 .addedToCart {
  background-color: #3ead34 !important; 
 }

 ::v-deep .toasted.bubble {
   background-color: #3ead34 !important; 
 }
 .linkSection{
   float: right;
   padding: 1.5%;
   padding-right: 3%;
   position: absolute;
 }
 .linkColor {
   color: #363636;
 }
 .nameTitle {
   margin-top: 30px;
 }
 .radiobtn {
    transform: translateY(17%);
 }

@media only screen and (max-width: 700px) {
  .radiobtn {
    transform: translateY(27%);
  }
}
</style>
