<template>
  <div class="container">
      <div class="central-body">
        <h2 class="subTitle title">מחשבון מחיר</h2>


        <div class="d-flex flex-column inputs align-items-center">
            <label for="fabric" class="mylabel">מחיר בד:</label>
            <div class="customWidth">
                <input type="text" v-model="fabric" id="fabric" name="fabric" class="form-control myinput">
            </div>
        </div>

        <div class="d-flex flex-column mt-4 align-items-center">
            <label for="size" class="mylabel">גודל:</label>
            <div class="customWidth">
                <input type="text" v-model="size" id="size" name="size" class="form-control myinput">
            </div>
        </div>

        <button class="btn green" @click="calculate()">חישוב</button>


        <h2 v-if='finalPrice != ""' class="subTitle">מחיר משוקלל: {{price}} ש"ח</h2>


        <div v-if='finalPrice != ""'><button class="btn blue" @click="displayDetailes()">פירוט</button>
        <div v-if="isDetailed" class="details">({{this.fabric}} * 3 + 25 + 120) * 1.17 * {{this.size}} = {{price}}</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fabric: "",
            size: "",
            finalPrice: "",
            isDetailed : false
        }
    },

    computed: {
        price: function () {
            return Math.round(this.finalPrice * 100) / 100
        }
    },

    methods: {
        calculate() {
        this.finalPrice = (this.fabric * 3 + 25 + 120) * 1.17 * this.size;
        },

        displayDetailes() {
            this.isDetailed = true
        },
      
        scrollToTop() {
                window.scrollTo(0,0);
        },
    },

    created() {
        this.scrollToTop();
    }
}

</script>

<style scoped>
.mylabel {
    display: inline-block;
    margin-bottom: 0.1rem;
    color: hsl(0deg 0% 0%);
    padding-right: 5px;
    font: bold;
}
.myinput {
    border-radius: 20px;
    width: 250px;
}
.inputs{
    margin-top: 5%;
}
.details {
    direction: ltr
}
body {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0e0620;
    font-size: 1em;
}
@media (max-width: 330px) {
  .couponBtn {
  height:100%;
  padding: 5px 20px 5px 20px;
  margin-top: 8px;
}
}
.couponBtn {
  height:100%;
  padding: 5px 20px 5px 20px;
}
@-webkit-keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.hvr-pop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pop:active {
  -webkit-animation-name: hvr-pop;
  animation-name: hvr-pop;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
ul {
    list-style-type: none;
    padding-inline-start: 35px;
}
svg {
    width: 100%;
    visibility: hidden;
}
h1 {
    font-size: 7.5em;
    margin: 15px 0px;
    font-weight: bold;
}
h2 {
    font-weight: bold;
}

.container {
    padding-top: 7%;
    padding-bottom: 7%;
}

.btn {
    z-index: 1;
    overflow: hidden;
    background: transparent;
    position: relative;
    padding: 8px 50px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1em;
    letter-spacing: 2px;
    transition: 0.2s ease;
    font-weight: bold;
    margin: 50px 0px 40px 0;
}
.btn.green {
    border: 4px solid #2ccf6d;
    color: #0e0620;
}
.btn.green:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: #2ccf6d;
    z-index: -1;
    transition: 0.2s ease;
}
.btn.green:hover {
    color: #fff;
    background: #2ccf6d;
    transition: 0.2s ease;
}
.btn.green:hover:before {
    width: 100%;
}

.btn.blue {
    border: 4px solid #031a44;
    color: #0e0620;
}
.btn.blue:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: #031a44;
    z-index: -1;
    transition: 0.2s ease;
}
.btn.blue:hover {
    color: #fff;
    background: #031a44;
    transition: 0.2s ease;
}
.btn.blue:hover:before {
    width: 100%;
}

@media screen and (max-width: 768px) {
    body {
        display: block;
}
    .container {
        margin-top: 40px;
        margin-bottom: 70px;
}
}
.title {
    font-size: calc(3.5em + 3vw);
    text-decoration: underline;
}

.subTitle {
    font-size: calc(1.2em + 0.6vw);
}
</style>
