<template>
<div id="nav" dir="rtl">

  <b-navbar toggleable="lg" type="dark" variant="dark" class="header customNavBar navHigh" fixed="top" v-bind:class="{'fullNavHight': this.isMobile,  'miniNavHight': !this.isMobile}">
    <b-navbar-brand class="name hvr-grow" @click="getAllProducts()" to="/" >וילונות מעצבים חללים</b-navbar-brand>

    <b-navbar-brand v-if="this.isMobile === false" class="hvr-grow pl-2 pr-1" to="/סל-קניות"><a href="/סל-קניות" id="cart"><font-awesome-icon  icon="shopping-cart"/>
      <span class="badge">{{this.cart.length}}</span></a></b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"><font-awesome-icon icon="bars"/> </b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>

      <b-navbar-nav v-if="!this.isMobile">
        <b-nav-item toggle-class="" class="hvr-grow" to="/">עמוד הבית</b-nav-item>
      </b-navbar-nav>


    <b-nav-item-dropdown  toggle-class="" class="a items float-center text-center" text=" קטלוג " right dir="rtl">
      <div class="option"><b-dropdown-item class="float-right textColor" to="/קטלוג/וילונות/וילונות-קצרים">וילונות קצרים</b-dropdown-item></div>
      <div class="option"><b-dropdown-item class="float-right textColor" to="/קטלוג/וילונות/וילונות-ארוכים">וילונות ארוכים</b-dropdown-item></div>
      <div class="option"><b-dropdown-item class="float-right textColor" to="/קטלוג/וילונות/וילונות-לחדר-שינה">וילונות לחדר שינה</b-dropdown-item></div>
      <div class="option"><b-dropdown-item class="float-right textColor" to="/קטלוג/וילונות/וילונות-פלדים-על-מסילה">וילונות פלדים על מסילה</b-dropdown-item></div>
      <div class="option"><b-dropdown-item class="float-right textColor" to="/קטלוג/וילונות/סט-4-חלקים">סט 4 חלקים</b-dropdown-item></div>
      <div class="option"><b-dropdown-item class="float-right textColor" to="/קטלוג/וילונות/וילונות-אטומים">וילונות אטומים</b-dropdown-item></div>
      <div class="option"><b-dropdown-item class="float-right textColor" to="/קטלוג/וילונות/וילון-גלילה-זברה">וילונות זברה</b-dropdown-item> </div>
      <div class="option"><b-dropdown-item class="float-right textColor" to="/קטלוג/מוטות">מוטות</b-dropdown-item></div>
      <div class="option"><b-dropdown-item class="float-right textColor" to="/קטלוג/מפות">מפות</b-dropdown-item></div>
      <div class="option"><b-dropdown-item class="float-right textColor" to="/קטלוג/הדומים">הדומים</b-dropdown-item></div>
      <div class="option"><b-dropdown-item class="float-right textColor" to="/קטלוג/שטיחים">שטיחים</b-dropdown-item></div>
      <div class="option"><b-dropdown-item class="float-right textColor" to="/קטלוג/כריות">כריות</b-dropdown-item></div>
      <div class="option"><b-dropdown-item class="float-right textColor" to="/קטלוג/וילונות/וילונות-לסלון-בהתאמה-אישית">וילונות בהתאמה אישית</b-dropdown-item></div>
      <div class="option"><b-dropdown-item class="float-right textColor" to="/קטלוג/וילונות/וילונות-לבית-בזול">וילונות לבית בזול</b-dropdown-item></div>
      
    </b-nav-item-dropdown>

      <b-navbar-nav>
        <b-nav-item toggle-class="" class="hvr-grow" to="/אודות">אודות</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav>
        <b-nav-item toggle-class="" class="hvr-grow" to="/מה-אמרו-עלינו">מה אמרו עלינו?</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="sbox">
        <b-nav-form  @submit.prevent>
          <b-form-input size="sm" v-model="text" class="mr-sm-2 searchInput hvr-grow" placeholder="חיפוש" 
          @keydown.enter="getByText(), goToCatalogPage()"></b-form-input>   

        </b-nav-form>
      </b-navbar-nav>

      <a class="phone mr-auto phones" href="tel:058-551-5533">לייעוץ: 058-551-5533</a>

      <b-navbar-brand v-if="this.isMobile === true" class="hvr-grow mr-auto pl-3" to="/סל-קניות"><a href="/סל-קניות" id="cart"><font-awesome-icon class="shopping_icon" icon="shopping-cart"/>
      <span class="badge">{{this.cart.length}}</span></a></b-navbar-brand>

    </b-collapse>
  </b-navbar>
</div>
</template>


<script>
import { mapActions, mapState } from 'vuex'
import $ from 'jquery'

export default {
   data() {
      return {
        text: '',
        isMobile: ''
      }
    },
    mounted() {
        if ( $( ".collapse" ).is( ":visible" )) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
    },
   computed: {
    ...mapState(['products', 'cart'])  
  },
methods: {
    ...mapActions(['initFilteredArray', 'setIsSearchFilter']),
    
    getByText() {
      this.initFilteredArray(this.products.filter(product => product.name.includes(this.text)));
      this.setIsSearchFilter(true);
      setTimeout(() => { this.goToCatalogPage()}, 1000);
    },
    goToCatalogPage() {
      this.scrollToTop();
      this.$router.push('/קטלוג').catch(err => {err})  
    },
    getAllProducts() {
      this.text='';
      document.title = 'קטלוג | וילונות מעצבים חללים';
      this.initFilteredArray(this.products);
      this.scrollToTop();
    },
    goToCart() {
      this.$router.push('/סל-קניות').catch(err => {err})  
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
 },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-dark {
    background-color: #1c4063 !important;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #f44336;
}

.mynav{
  position: fixed;
  top: 0;
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
  height: 10%;   
  }

#nav {
  z-index: 9999;
}

.x-navbar-wrap {
     position:fixed;
     width:100%;
     top:0;
     z-index:99999;
}
.first {
  margin-right: 35px;
}

/* change the brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
    color: rgba(14, 13, 13, 0.8);
}
/* change the link color */
.navbar-custom .navbar-nav .nav-link {
    color: white;
}
/* change the color of active or hovered links */

.search-box {
  cursor: pointer;
  margin: 0 auto;
  border: .5px solid rgb(232, 238, 241);
  border-radius: 28px;
  color: rgb(119, 126, 128);
  text-align: center;
  -o-text-overflow: clip;
  text-overflow: clip;
  letter-spacing: 1px;
  /* background: #fff; */
  border: 1px solid #ff5900;
  margin-right: 50%;  
  width: 160%
}

.search-icon {
  padding: 5px;
  border-radius: 24px;
  float: right;
}
.inner-addon { 
  position: relative; 
}
/* style icon */
.inner-addon .glyphicon {
  position: absolute;
  padding: 13px;
  pointer-events: none;
}
.left-addon .glyphicon  { left:  0px;}
.right-addon .glyphicon { right: 0px;}
.left-addon input  { padding-left:  30px; }
.right-addon input { padding-right: 30px; }
.item {
  margin-left: 5%;
}
.uls {
  width: 100%;
  height: 100%;
}
.round {
    width: 100%;
    border-radius: 15px;
    border: 1px #000 solid;
    padding: 5px 5px 5px 25px;
}

.corner {
    position: absolute;
    top: 3px;
    left: 5px;
    height: 20px;
    width: 20px;
    z-index: 10;
    border-radius: 10px;
    border: none;
    background: #000; /* Set the bg image here. with "no-repeat" */
}
.form-inline .form-control {
  display: inline-block;
  vertical-align: middle;
  width: 200%;
  height: 5%;
}
.items {
    margin-right: 10%;
}

.sbox{
  width: 40%;
}

@media only screen and (max-width: 500px) {
  .sbox{
    margin-right: 20%;
    width: 60%;
  }
}

@media only screen and (max-width: 992px) {
  .sbox{
    margin-right: 20%;
    width: 60%;
  }
}

@media only screen and (min-width: 992px) {
  .sbox{
    margin-right: auto;
    width: 25%;
  }
}

.navbar-light:hover .navbar-nav:hover .nav-link:hover {
    color: 
    #ff5900;
}
.form-inline {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
}
@media only screen and (min-width: 1000px) {
.name {
  color: white;
  font-weight: bold;
  font-size: calc(0.8em + 0.6vw);
  margin: 0px auto;
  text-align: center;
}
}
@media only screen and (max-width: 500px) {
.name {
  color: #fbffff;
  -webkit-background-clip: text;
  font-weight: bold;
  font-size: calc(0.8em + 1vw);
  
  margin: 0px auto;
  text-align: center;

}
}
@media only screen and (max-width: 300px) {
.name {
  color: #fbffff;
  -webkit-background-clip: text;
  font-weight: bold;
  font-size: calc(0.5em + 1vw);
  
  margin: 0px auto;
  text-align: center;

}
}

@media only screen and (max-width: 250px) {
.name {
  color: #fbffff;
  -webkit-background-clip: text;
  font-weight: bold;
  font-size: calc(0.4em + 1vw);
  margin: 0px auto;
  text-align: center;

}
}

.name {
  color: white;
  font-weight: bold;
  margin: 0px auto;
  text-align: center;
}

.items{
  margin-right: 5%;
}
.searchInput {
    background-color: #fff;
    border: 2px solid #ca8258;
    border-radius: 25px;
    cursor: default;
    margin-top: 2px;
    padding: 11px 25px;
    width: 100%;
    line-height: 18px;
}

.textColor {
  color: #ff5900 !important;
}
.navbar-light .navbar-nav .nav-link {
    color: white !important
}


/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

::v-deep .nav-link {
  -webkit-background-clip: text;
 font-weight: bold;
  font-size: 110%;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

::v-deep .nav-link:hover, ::v-deep .nav-link:focus, ::v-deep .nav-link:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.phone {
  color: #fbffff;
  -webkit-background-clip: text;
  font-weight: bold;
  font-size: calc(0.5em + 0.5vw);
}

@media only screen and (min-width: 1180px) {

.phone {
  color: #fbffff;
  -webkit-background-clip: text;
  font-weight: bold;
  font-size: calc(0.7em + 0.5vw);
}
}

@media only screen and (min-width: 995px) {

.phone {
  color: #fbffff;
  -webkit-background-clip: text;
  font-weight: bold;
  font-size: calc(0.5em + 0.5vw);
  padding-right: 0%
}
}
.badge {
  color: #ca8258;
  display: inline-block;
  border: 2px solid;
  font-size: 11px;
  line-height: 1;
  padding: 4px 7px;
  text-align: center;
  vertical-align: middle;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  margin-bottom: 2px;
}
.navbar-left {
  float: right;
}
.phones {
  white-space: nowrap;
  overflow: hidden;
}
.option {
width: 100%;
display: inline-block;
color:#f44336;
}
#nav ::v-deep a {
  font-weight: bold;
  color: #424242fa;
}
#nav ::v-deep a:hover {
  font-weight: bold;
  color: #ff5900;
}

#nav ::v-deep a:focus {
  font-weight: bold;
  background-color: white;
}

::v-deep .textColor {
  color: #1c4063;
}
/* ::v-deep .dropdown-menu.show {
  background: #1c4063;
} */

.customNavBar {
  background-color: #ffffff !important;
  -webkit-box-shadow: 0 10px 15px rgb(25 25 25 / 10%);
}
.navbar-dark .navbar-toggler {
  color: #ca8258 !important;
  border-color: hsl(0deg 0% 100% / 2%);
  outline: none;
}
.navbar-dark .navbar-toggler:focus {
  color: hsl(0deg 0% 100% / 2%);
  border-color: hsl(0deg 0% 100% / 2%);
}
.fullNavHight{
  padding: 24px;
}
.miniNavHight{
  padding: 18px;
}
.shopping_icon {
  color: #000000;
}
.navbar-collapse {
  background-color: white;
}

</style>
