<template>
  <div class="container">
      <div class="central-body">
        <h1 class="title">404</h1>
        <h2 class="subTitle">נראה שהגעת לעמוד שאינו קיים</h2>
        <a href="/" class="btn green">לעמוד הבית</a>
      </div>
    </div>
</template>

<script>
export default {
  methods: {
      
   scrollToTop() {
        window.scrollTo(0,0);
   },
  },
  created() {
    this.scrollToTop();
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');

html, body {
    height: 100%;
}

body {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Nunito Sans";
    color: #0e0620;
    font-size: 1em;
}

button {
    font-family: "Nunito Sans";
}

ul {
    list-style-type: none;
    padding-inline-start: 35px;
}
svg {
    width: 100%;
    visibility: hidden;
}
h1 {
    font-size: 7.5em;
    margin: 15px 0px;
    font-weight: bold;
}
h2 {
    font-weight: bold;
}

.container {
    padding-top: 10%;
    padding-bottom: 10%;
}

.btn {
    z-index: 1;
    overflow: hidden;
    background: transparent;
    position: relative;
    padding: 8px 50px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1em;
    letter-spacing: 2px;
    transition: 0.2s ease;
    font-weight: bold;
    margin: 2% 0px;
}
.btn.green {
    border: 4px solid #2ccf6d;
    color: #0e0620;
}
.btn.green:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: #2ccf6d;
    z-index: -1;
    transition: 0.2s ease;
}
.btn.green:hover {
    color: #fff;
    background: #2ccf6d;
    transition: 0.2s ease;
}
.btn.green:hover:before {
    width: 100%;
}
@media screen and (max-width: 768px) {
    body {
        display: block;
}
    .container {
        margin-top: 70px;
        margin-bottom: 70px;
}
}
.title {
    font-size: calc(3.5em + 3vw);
}

.subTitle {
    font-size: calc(1.4em + 0.6vw);
}
</style>
