<template>
<div>
	<!-- Slide1 -->
	<section class="slide1">
		<div class="wrap-slick1">
			<div class="slick1">
				<div class="item-slick1 backgroundOverlay" v-lazy:background-image="backgroundImage">
					<div class="wrap-content-slide1 sizefull flex-col-c-m p-l-15 p-r-15">
						<span class="caption1-slide1 upText t-center animated m-b-15 borderText"
						data-aos="fade-down"
						data-aos-duration="400">
							All For Home
						</span>

						<h2 class="caption2-slide1 btmText t-center animated borderText"  
						data-aos="fade-up"
						data-aos-delay="700"
						data-aos-duration="500">
							וילונות מעצבים חללים
						</h2>

						<div class="wrap-btn-slide1 w-size1 animated">
							<!-- Button -->
							<a href="/קטלוג" @click="getAllProducts()" 
							class="flex-c-m size2 bo-rad-23 s-text2 mainBtn bgwhite hov1 trans-0-4"
							data-aos="fade-up"
							data-aos-delay="1000"
							data-aos-duration="800">
								לקטלוג המלא
							</a>
							<a href="#products" class="arrow"></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

<div class="section-one" id="products">

	<!-- Banner -->
	<section class="banner bgwhite p-t-40">
		<div class="container">
			<div class="row">

				<div class="col-sm-10 col-md-8 col-lg-8 m-l-r-auto">
					<!-- block1 -->
					<div class="block1 hov-img-zoom pos-relative m-b-30"  
					data-aos="fade-up"
					data-aos-anchor-placement= "top"
					data-aos-duration="300">
						<img src="../assets/zebra_theme5.jpg" alt="וילונות גלילה זברה">

						<div class="block1-wrapbtn w-size2">
							<!-- Button -->
							<a href="/קטלוג/וילונות/וילון-גלילה-זברה" class="flex-c-m size2 m-text2 bg3 hov1 trans-0-4">
								וילונות גלילה זברה
							</a>
						</div>
					</div>
                </div>

                <div class="col-sm-10 col-md-8 col-lg-4 m-l-r-auto">
					<!-- block1 -->
					<div class="block1 hov-img-zoom pos-relative m-b-30" 
					>
						<img src="../assets/long_theme4.jpg" alt="וילונות ארוכים">

						<div class="block1-wrapbtn w-size2">
							<!-- Button -->
							<a href="/קטלוג/וילונות/וילונות-ארוכים" class="flex-c-m size2 m-text2 bg3 hov1 trans-0-4">
								וילונות ארוכים 
							</a>
						</div>
					</div>
                </div>
            </div>

            <div class="row">

                <div class="col-sm-10 col-md-8 col-lg-4 m-l-r-auto">
					<div class="block1 hov-img-zoom pos-relative m-b-30" 
					data-aos="fade-up"
					data-aos-duration="300">
						<img src="../assets/crepe_back.jpg" alt="וילונות קצרים">

						<div class="block1-wrapbtn w-size2">
							<!-- Button -->
							<a href="/קטלוג/וילונות/וילונות-קצרים" class="flex-c-m size2 m-text2 bg3 hov1 trans-0-4">
								וילונות קצרים
							</a>
						</div>
					</div>
				</div>

                <div class="col-sm-10 col-md-8 col-lg-4 m-l-r-auto">
					<!-- block1 -->
					<div class="block1 hov-img-zoom pos-relative m-b-30" 
					data-aos="fade-up"
					data-aos-duration="300">
						<img src="../assets/pillow_corderoy.jpg" alt="כריות נוי">

						<div class="block1-wrapbtn w-size2">
							<!-- Button -->
							<a href="/קטלוג/כריות" class="flex-c-m size2 m-text2 bg3 hov1 trans-0-4">
								כריות נוי
							</a>
						</div>
					</div>
				</div>

                <!-- block1 -->
                <div class="col-sm-10 col-md-8 col-lg-4 m-l-r-auto">
					<div class="block1 hov-img-zoom pos-relative m-b-30" 
					data-aos="fade-up"
					data-aos-duration="300">
						<img src="../assets/carpet_theme.jpg" alt="שטיחים">

						<div class="block1-wrapbtn w-size2">
							<!-- Button -->
							<a href="/קטלוג/שטיחים" class="flex-c-m size2 m-text2 bg3 hov1 trans-0-4">
								שטיחים
							</a>
						</div>
					</div>
				</div>

			</div>
		</div>
	</section>
  
    <!-- begin Separator -->
  <div class="separator"> 
    <svg class="separator__svg" width="100%" height="300" viewBox="0 0 100 100" preserveAspectRatio="none" fill="#a06e4d" version="1.1" xmlns="http://www.w3.org/2000/svg">
       <path d="M 100 100 V 10 L 0 100"/>
       <path d="M 30 73 L 100 18 V 10 Z" fill="#AF7F5F" stroke-width="0"/>
      </svg>
  </div>
  <!-- end Separator -->
</div>
<div class="section-two" >
  <h2 class="section-two__title">מי אנחנו?</h2>
 
<h5 class="section-two__descr">כולנו אוהבים סביבת מגורים ועבודה המותאמים להעדפות האישיות שלנו.</h5>
<h5 class="section-two__descr">וילונות הינם פריט עיצוב שיכול לחולל פלאים,</h5>
<h5 class="section-two__descr">באמצעותם נוכל לתת ביטוי לטעם האישי שלנו ולייצר את המרחב והאווירה הרצויים לנו.   </h5>
<h5 class="section-two__descr">אנו עוסקים בתחום מזה 30 שנה, ומציעים שירות מקצועי אמין ואיכותי כאשר הלקוח שלנו נמצא תמיד במרכז. </h5>
<h5 class="section-two__descr">השירות שלנו ניתן על בסיס אישי ובפגישה בבית הלקוח או במשרדו.</h5>
<h5 class="section-two__descr">אנו לומדים על הלקוח ועל צרכיו, כגון: האפלה, הצללה, תיחום, נוי ואוירה מתאימה.</h5>
<h5 class="section-two__descr">אנו עובדים עם וילונות ובדים איכותיים במגוון של סגנונות כגון: ונציאני, רומאי, גלילה, בדים להצללה, לאטימה ומציעים וילונות מוכנים או בהתאמה אישית ובהתאם להעדפת הלקוח ולתקציב העומד לרשותו.</h5>
<h3 class="special" data-aos="fade-up"
					data-aos-duration="300">פנו אלינו עוד היום לתיאום פגישה ללא התחייבות! </h3>





    <!-- ***** Testimonials Start ***** -->
    <section class="section">
        <div class="container">
            <!-- ***** Section Title Start ***** -->
            <div class="row">
                <div class="col-lg-12">
                    <div class="center-heading">
                        <h2 class="section-two__title">מה אמרו עלינו?</h2>
                    </div>
                </div>
                <p class="section-two__descr mb-4">אל תסמכו רק עלינו, תסמכו על מה שהלקוחות אומרים!</p>
            </div>
            <!-- ***** Section Title End ***** -->

            <div class="row mt-4">
                <!-- ***** Testimonials Item Start ***** -->
                <div class="reviewCard col-lg-4 col-md-6 col-sm-12">
                    <div class="team-item">
                        <div class="team-content">
                            <i><font-awesome-icon  icon="quote-right"/></i>
                             
                            <p>קניתי שטיח דרך מירי המקסימה!
                          אישה סבלנית, שירות מושלם!!
                          קניתי בלי לראות איך זה במציאות נתנה לי הרגשת ביטחון ונתנה לי את כל הספייס בשביל להחליט לבד מבלי לחץ!
                          תודה רבה!!
                          ממליצה בחום!</p>
                          
                            <div class="user-image">
                                <a href="https://www.facebook.com/hadar.rhamim/posts/3418056631632487"><img src="../assets/facebook-icon.png" class="icon" alt=""></a>
                            </div>
                            <div class="team-info">
                              <i><span class="stars"><font-awesome-icon class="rating" icon="star"/><font-awesome-icon class="rating" icon="star"/><font-awesome-icon class="rating" icon="star"/><font-awesome-icon class="rating" icon="star"/><font-awesome-icon class="rating" icon="star"/></span></i>
                              <h3 class="user-name">הדר וניר רחמים</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ***** Testimonials Item End ***** -->
                
                <!-- ***** Testimonials Item Start ***** -->
                <div class="reviewCard col-lg-4 col-md-6 col-sm-12">
                    <div class="team-item">
                        <div class="team-content">
                            <i><font-awesome-icon  icon="quote-right"/></i>

                            <p>המלצה מכל הלב ! אחרי סקר שוק רחב הגעתי לאישה מקסימה עם וילונות איכותיים ומחירים הוגנים !
                                השירות היה אדיב ומהיר . הוילונות בהשוואה לוילונות אחרים איכותיים מאוד . הזמנתי לכל הבית ואני מרוצה!</p>
                            <div class="user-image">
                                <a href="https://www.facebook.com/alon.ofir.54/posts/10221543216103203"><img src="../assets/facebook-icon.png" class="icon" alt=""></a>
                            </div>
                            <div class="team-info">
                              <i><span class="stars"><font-awesome-icon class="rating" icon="star"/><font-awesome-icon class="rating" icon="star"/><font-awesome-icon class="rating" icon="star"/><font-awesome-icon class="rating" icon="star"/><font-awesome-icon class="rating" icon="star"/></span></i>
                              <h3 class="user-name">Alon Ofir</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ***** Testimonials Item End ***** -->
                
                <!-- ***** Testimonials Item Start ***** -->
                <div class="reviewCard col-lg-4 col-md-6 col-sm-12">
                    <div class="team-item">
                        <div class="team-content">
                            <i><font-awesome-icon  icon="quote-right"/></i>
                            
                            <p>איכות מעולה, מחיר נוח לכל כיס ושירות מהלב!
                                מירי המקסימה כיוונה אותי ואני כלכך שמחה על הבחירה! ממליצה לכל מי שקצת מתלבט לדבר איתה ואני בטוחה שהיא תמצא פתרון מושלם! אין עליכם!!!!!!❤️
                            </p>
                            <div class="user-image">
                                <a href="https://www.facebook.com/karin.kallner/posts/10224170023507121"><img src="../assets/facebook-icon.png" class="icon" alt=""></a>
                            </div>
                            <div class="team-info">
                              <i><span class="stars"><font-awesome-icon class="rating" icon="star"/><font-awesome-icon class="rating" icon="star"/><font-awesome-icon class="rating" icon="star"/><font-awesome-icon class="rating" icon="star"/><font-awesome-icon class="rating" icon="star"/></span></i>
                              <h3 class="user-name">Karin Kallner</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ***** Testimonials Item End ***** -->
            </div>
        </div>
    </section>
    <!-- ***** Testimonials End ***** -->


      <a href="/מה-אמרו-עלינו" ><button class="btn">לביקורות נוספות</button></a>

 <!-- כרטיסי ביקורות מפייסבוק -->


<!-- <div class="main">
	<ul class="cards">
     
    <li class="cards_item">
      <div class="card">
        <div class="text">קניתי שטיח דרך מירי המקסימה!
                          אישה סבלנית, שירות מושלם!!
                          קניתי בלי לראות איך זה במציאות נתנה לי הרגשת ביטחון ונתנה לי את כל הספייס בשביל להחליט לבד מבלי לחץ!
                          תודה רבה!!
                          ממליצה בחום!</div>
        <div class="footer">
          <a class="image"  href="https://www.facebook.com%2Fhadar.rhamim%2Fposts%2F3418056631632487"></a>
          <h3 class='person'>הדר וניר רחמים</h3>
      </div>
    </div>
    </li>
     <li class="cards_item">
      <div class="card">
        <div class="text">קניתי שטיח דרך מירי המקסימה!קניתי בלי לראות איך זה במציאות נתנה לי הרגשת ביטחון ונתנה לי את כל הספייס בשביל להחליט לבד מבלי לחץ!
                          תודה רבה!!
                          ממליצה בחום!</div>
        <div class="footer">
          <a class="image"  href="https://www.facebook.com%2Fhadar.rhamim%2Fposts%2F3418056631632487"></a>
          <h3 class='person'>הדר וניר רחמים</h3>
      </div>
    </div>
    </li>
  </ul>

</div> -->

<!-- וילונות בהתאמה אישית -->

<!-- <div class='some-page-wrapper'>
  <div class='row'>

    <div class='column'>
		<div>
        וילונות בהתאמה אישית
		</div> 
		<div>
		אנו מגיעים לבית הלקוח בלה בלה בלה
		אנו מגיעים לבית הלקוח בלה בלה בלה
		אנו מגיעים לבית הלקוח בלה בלה בלה
		אנו מגיעים לבית הלקוח בלה בלה בלה

		</div>
	</div>

    <div class='column'>
		<div class="block1 pos-relative m-b-30" 
			data-aos="fade-up"
			data-aos-duration="300">
			<img src="../assets/custom_theme.jpg" class="themeImg" alt="IMG-BENNER">
		</div>	
    </div>
</div>

</div> -->



	<!-- Shipping -->
	<section class="shipping">
		<div class="flex-w p-l-15 p-r-15">
			<div class="flex-col-c w-size5 p-l-15 p-r-15 p-t-16 p-b-15 respon1">
				<h4 class="m-text12 t-center">
					100% קניה מאובטחת
				</h4>

				<span class="s-text11 t-center">
					קנייה מהירה ומאובטחת בפייפאל, כרטיס אשראי או ביט
				</span>
			</div>

			<div class="flex-col-c w-size5 p-l-15 p-r-15 p-t-16 p-b-15 bo2 respon2">
				<h4 class="m-text12 t-center">
					החזרות חינם
				</h4>

				<span class="s-text11 t-center">
					קיבלת מוצר וגילית שהוא לא מתאים לחדר? אין בעיה! ניתן להחזיר בפשטות מוצרים ולקבל החזר
				</span>
			</div>

			<div class="flex-col-c w-size5 p-l-15 p-r-15 p-t-16 p-b-15 respon1">
				<h4 class="m-text12 t-center">
					משלוחים מהירים
				</h4>

				<span class="s-text11 t-center">
					משלוחים מהירים עד הבית או נקודות חלוקה
				</span>
			</div>
		</div>
	</section>
	</div>

</div>
</template>


<script>
// import $ from 'jquery'
import { mapActions, mapState } from 'vuex'
import AOS from 'aos';

export default {
	data() {
		return {
			backgroundImage : require("../assets/theme_image.jpg")
		}
	},
	created(){
		AOS.init();
    this.scrollToTop();
	},
 computed: {
    ...mapState(['products'])  
  },
  methods: {
    ...mapActions(['initFilteredArray']),
    
      getAllProducts() {
        document.title = 'קטלוג - וילונות מעצבים חללים',
        this.initFilteredArray(this.products);
    },
    scrollToTop() {
          window.scrollTo(0,0);
    },
  },
}
</script>

<style scoped>
.bo-rad-23 {border-radius: 23px;}
.block1-wrapbtn {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20px;
  box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.1);
}
.w-size2 {
	width: 179px;
}

.flex-c-m {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
}
.size2 {
	width: 100%;
	height: 46px;
}

.m-text2 {
	font-size: 16px;
	color: #333333;
	text-transform: uppercase;
}

.bg3 {
  background-color: rgba(255,255,255,0.95);
  box-shadow: 2px 3px 10px 2px #4644444d;
}

.hov1:hover {
    background-color: #e65540;
    color: white;
}
.trans-0-4 {
	-webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}
	.respon1 {
		width: 100%;
	}

	.respon2 {
		width: 100%;
		border-left: none;
		border-right: none;
	}

    .flex-col-c {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
}
.bo2 {
	border-left: 1px solid #d9d9d9;
	border-right: 1px solid #d9d9d9;
}
.t-center {text-align: center;}
.w-size5 {
	width: calc(100% / 3);
}
.p-l-15 {padding-left: 15px;}
.p-r-15 {padding-right: 15px;}
.flex-w {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
}
.s-text11 {
	font-size: calc(0.6em + 0.6vw);
	color: #000000;
	line-height: 1.8;
}
.p-t-16 {padding-top: 16px;}
.p-b-15 {padding-bottom: 15px;}
.m-text12 {
	font-size: calc(1em + 0.7vw);
	color: #ffffff;
	line-height: 1.5;
}
.p-t-62 {padding-top: 62px;}
.p-b-46 {padding-bottom: 46px;}
.block1-wrapbtn {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20px;
  box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.1);
}
.pos-relative {position: relative;}
.m-b-30 {margin-bottom: 30px;}
.hov-img-zoom {
    display: block;
    overflow: hidden;
}
.hov-img-zoom img{
    width: 100%;
    -webkit-transition: all 0.6s;
    -o-transition: all 0.6s;
    -moz-transition: all 0.6s;
    transition: all 0.6s;
}
.hov-img-zoom:hover img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}
.trans-0-4 {
	-webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.zoomIn {
  animation-name: zoomIn;
}
.bgwhite {
    background-color: white;  
    padding: 5px 10px 5px 10px;
    color: black;
	padding-bottom: 40px;
}
@media (max-width: 992px) {
  .wrap-content-slide1 .xl-text2 {
  font-size: calc(1.6em + 1.6vw);
  }
}

.item-slick1 {
  height: 570px;
}

@media (max-width: 768px) {
  .wrap-content-slide1 .xl-text3,
  .wrap-content-slide1 .xl-text2,
  .wrap-content-slide1 .xl-text1 {
      font-size: calc(1em + 1vw);

  }

  .wrap-content-slide1 .m-text27,
  .wrap-content-slide1 .m-text1 {
    font-size: 16px;
  }

  .item-slick1 {
    height: 370px;
  }
}

@media (max-width: 1300px) {
  .wrap-content-slide1 .xl-text3,
  .wrap-content-slide1 .xl-text2,
  .wrap-content-slide1 .xl-text1 {
      font-size: calc(1.5em + 1vw);
  }

  .wrap-content-slide1 .m-text27,
  .wrap-content-slide1 .m-text1 {
    font-size: 16px;
  }

  .item-slick1 {
    height: 500px;
  }
}
@media (max-width: 1700px) {
  .item-slick1 {
    height: 600px;
  }
}

@media (max-width: 1300px) {
  .item-slick1 {
	background-size: cover;
  }
}


.sizefull {
	width: 100%;
	height: 100%;
}

.wrap-slick1 {
  position: relative;
}
.flex-col-c-m {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}
.p-t-150 {padding-top: 150px;}
.p-b-170 {padding-bottom: 170px;}
.p-t-40 {padding-top: 40px;}
.p-b-40 {padding-bottom: 40px;}

.mainBtn {
	padding: 5px 40px 5px 40px;
	color: #924040;
}
.m-l-r-auto{
	margin-left:auto;
	margin-right: auto;
}

/* arrow */
.arrow,
.arrow:before {
  position: absolute;
  left: 50%;
}

.arrow {
  width: 40px;
  height: 40px;
  margin: 8% 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-radius: 2px;
  border-right: 2.5px rgb(218, 67, 21) solid;
  border-bottom: 2.5px rgb(218, 67, 21) solid;
}

.arrow:before {
  content: "";
  width: 20px;
  height: 20px;
  margin: 8% 0 0 -16px;
  border-left: none;
  border-top: none;
  border-right: 2px rgb(218, 67, 21) solid;
  border-bottom: 2px rgb(218, 67, 21) solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

@media only screen and (min-width: 1500px) {
.arrow {
  width: 40px;
  height: 40px;
  margin: 5% 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-radius: 2px;
  border-right: 2.5px rgb(218, 67, 21) solid;
  border-bottom: 2.5px rgb(218, 67, 21) solid;
}

.arrow:before {
  content: "";
  width: 20px;
  height: 20px;
  margin: 5% 0 0 -16px;
  border-left: none;
  border-top: none;
  border-right: 2px rgb(218, 67, 21) solid;
  border-bottom: 2px rgb(218, 67, 21) solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}
.themeImg {
	max-width:100%;
}

.some-page-wrapper {
  margin: 15px;
}
.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.blue-column {
  background-color: blue;
  height: 100px;
}

.double-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

@media screen and (min-width: 800px) {
  .column {
    flex: 1
  }

  .double-column {
    flex: 2
  }
}

/** seperate section line*/
body {
	overflow-x: hidden;
}
.section-one {
	background-color: #ffffff;
	position: relative;
	padding: 40px 0 250px;
}
.section-one .section-one__title {
	color: #fff;
	font-size: 35px;
	/* margin-bottom: 30px; */
	text-align: center;
}
.section-one .section-one__descr {
	color: #fff;
	font-size: 16px;
	line-height: 1.5;
	max-width: 300px;
	margin: 0 auto;
	text-align: center;
}
.section-two {
	background-color: #a06e4d;	
	padding: 80px 0 50px;
	position: relative;
	z-index: 10;
}
.section-two .section-two__title {
	color: #fff;
	font-size: calc(1.6em + 0.6vw);
	margin-bottom: 30px;
	text-align: center;
}
.section-two .section-two__descr {
	color: #fff;
	font-size: calc(0.7em + 0.6vw);	
	line-height: 1.5;
	max-width: 50%;
	margin: 0 auto;
	text-align: center;
}

@media screen and (max-width: 1000px) {
.section-two .section-two__descr {
	color: #fff;
	font-size: calc(1em + 0.6vw);
	line-height: 1.5;
	max-width: 70%;
	margin: 0 auto;
	text-align: center;
}

}
/* ------------------------------------------------------------------------- begin Separator * ------------------------------------------------------------------------- */
.separator {
	bottom: -4px;
	left: 0;
	overflow: hidden;
	position: absolute;
	width: 130%;
}
/* ------------------------------------------------------------------------- end Separator * ------------------------------------------------------------------------- */
/* begin Media Max-Width 767 ============================================================================ */
@media screen and (max-width: 767px) {
	.section-one {
		padding: 60px 0 100px;
}
	.separator {
		bottom: -200px;
}
	.separator .separator__svg {
		left: -20%;
		position: relative;
		transform: rotate(35deg);
		/* width: 140%; */
}
.bgwhite {
    background-color: white;  
    padding: 5px 10px 5px 10px;
    color: black;
}
}
/* end Media Max-Width 767 ============================================================================ */

.special {
	margin-top: 5%;
    color: #ffffff;
	font-weight: bold;
	font-size: calc(1.2em + 0.7vw);
}
.shipping {
	padding-top: 100px;
}
.upText {
	font-size: calc(1.6em + 1vw);
}
.btmText {
	font-size: calc(1em + 0.7vw);
	margin-bottom: 20px;
	margin-top: 0.5%;
}
.borderText {
	color: white;
	text-shadow: black 0px 0px 10px;
}

@media (min-width: 1300px) {
.slick1 {
	padding-top: 70px;
}

.backgroundOverlay {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../assets/theme_image.jpg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
	background-size: 1024px !important;
}
}

@media (min-width: 2000px) {
.slick1 {
	padding-top: 75px;
}
}

@media (max-width: 1300px) {
.backgroundOverlay {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../assets/theme_image.jpg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
	background-size: cover !important;
}
}


.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 2%;
  padding-bottom: 5%;
}

.cards_item {
  padding: 1rem;
}

@media (min-width: 40rem) {
  .cards_item {
    /* width: 50%; */
    width: 100%;
  }
}

@media (min-width: 56rem) {
  .cards_item {
    /* width: 33.3333%; */
    width: 50%;
  }
}

.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0,0,0,.3);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 500px;
}

.card_content {
  padding: 1rem;
  background: linear-gradient(to bottom left, #174caa 40%, #69b7e4 100%);
}

.card_title {
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0px;
}

.card_text {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;    
  font-weight: 400;
}
.text{
  padding: 2.2em;
  line-height: 1.7em;
  position: relative;
  font-size: 18px;
}

.footer{
  background: linear-gradient(90deg, #1a2B96 0%, #2b87d2 100%);
  height: 140px;
  border-radius: 0 0 10px 10px;
  position:relative;
  
}

.quote{
  font-size: 400%;
  float: right;
  opacity: .1;
  transform: rotate(10deg) translate(-10px, -40px);
 color:#4D3FA3;
}

.person{
  color:white;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size:1.5rem;
  font-weight: 600;
}

@media (max-width: 350px) {
.person{
  bottom: 1%;
}
}
.main{
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 4%;
}

/* 
---------------------------------------------
reviews css
--------------------------------------------- 
*/

.container{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto}
@media (min-width:576px){
  .container{max-width:540px}
  }
@media (min-width:768px){.container{max-width:720px}}
@media (min-width:992px){.container{max-width:960px}}@media (min-width:1200px){.container{max-width:1140px}}.container-fluid{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto}


.section {
  position: relative;
  padding-top: 100px;
  padding-bottom: 40px;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (min-width: 992px){
.col-lg-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}}
.center-heading {
  text-align: center;
}
.center-heading .section-title {
  font-weight: 500;
  font-size: 28px;
  color: #1e1e1e;
  letter-spacing: 1.75px;
  line-height: 38px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
.offset-lg-3 {
    margin-left: 25%;
}}
@media (min-width: 992px){
.col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}}
.center-text {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    color: #777;
    line-height: 28px;
    letter-spacing: 1px;
    margin-bottom: 50px;
}
.center-text p {
    font-size: 15px;
    color: #777;
    margin-bottom: 30px;
}
.team-item .user-image {
    width: 60px;
    height: 60px;
    overflow: hidden;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    margin-left: 25px;
    float: left;
}
.left-heading.light .section-title {
  color: #ffffff;
}

.left-heading .section-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  color: #1e1e1e;
  letter-spacing: 0.25px;
  margin-bottom: 30px;
  position: relative;
}
.center-heading .section-title {
  font-weight: 500;
  font-size: 28px;
  color: #1e1e1e;
  letter-spacing: 1.75px;
  line-height: 38px;
  margin-bottom: 20px;
}

.center-heading.colored .section-title {
  color: #ffffff;
}
.center-text {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: #777;
  line-height: 28px;
  letter-spacing: 1px;
  margin-bottom: 50px;
}

.center-text.colored {
  color: #fff;
}

.center-text p {
  font-size: 15px;
  color: #777;
  margin-bottom: 30px;
}

/* 
--------
team
-------- 
*/
.team-item {
  background: #fff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.10);
  border-radius: 20px;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 25px;
  padding-right: 25px;
  padding-left: 25px;
}

@media (max-width:576px){
.reviewCard {
  padding-right: 11%;
  padding-left: 11%;
}
}

.team-item:hover .team-content .team-info {
  margin-left: 30px;
}

.team-item i {
  display: block;
  margin-top: 5px;
  font-size: 30px;
  color: #d86b02d9;
  text-align: center;
}

.team-item .team-content {
  text-align: left;
  overflow: hidden;
}

.team-item .team-content .team-info {
  float: left;
  margin-left: 20px;
  margin-top: 8px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.team-item .team-content .team-info .user-name {
  display: block;
  font-weight: 400;
  font-size: 17px;
  color: #1e1e1e;
  letter-spacing: 0.25px;
  margin-bottom: 5px;
}

.team-item .team-content .team-info span {
  display: block;
  font-weight: 400;
  font-size: 15px;
  color: #d86b02d9;
  letter-spacing: 0.25px;
}

.team-item .team-content p {
  margin-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 25px;
  font-weight: 400;
  font-size: 15px;
  color: #161616;
  letter-spacing: 0.6px;
  line-height: 26px;
  text-align: right;
}
.icon {
  width: 60px;
}

.rating {
  font-size: 18px;
}
/* 
---------------------------------------------
End of reviews css
--------------------------------------------- 
*/


.btn {
  color: #ffffff;
  padding: 0.8rem;
  font-size: 18px;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 500;
  display: block;
  bottom: 0;
  width: 280px;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
}
.btn:hover {
  background-color: rgba(255, 255, 255, 0.12);
  text-decoration: underline;
}
</style>
