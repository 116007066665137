<template>
  <div id="app">
    <TopNavbar></TopNavbar>
    <router-view/>
    <div id="footer" class="footertxt">


<div class='some-page-wrapper'>
  <div class='row'>
    <div class='column'>
        <router-link class="linkColor" to="/קטלוג/וילונות/וילונות-ארוכים">וילונות ארוכים</router-link>
    </div>
    <div class='column'>
      <router-link class="linkColor" to="/קטלוג/וילונות/וילונות-קצרים">וילונות קצרים</router-link>
    </div>
    <div class='column'>
      <router-link class="linkColor" to="/קטלוג/וילונות/וילונות-לחדר-שינה">וילונות לחדר שינה</router-link>
    </div>
  </div>
</div>

<div class='some-page-wrapper'>
 <div class='row'>
    <div class='column'>
        <router-link class="linkColor" to="/קטלוג/וילונות/סט-4-חלקים">סט 4 חלקים</router-link>
    </div>
    <div class='column'>
      <router-link class="linkColor" to="/קטלוג/וילונות/וילונות-אטומים">וילונות אטומים</router-link>
    </div>
    <div class='column'>
      <router-link class="linkColor" to="/קטלוג/וילונות/וילון-גלילה-זברה">וילונות גלילה זברה</router-link>
    </div>
  </div>
</div>

<div class='some-page-wrapper'>
<div class='row'>
    <div class='column'>
        <router-link class="linkColor" to="/קטלוג/שטיחים">שטיחים</router-link>
    </div>
    <div class='column'>
      <router-link class="linkColor" to="/קטלוג/כריות">כריות</router-link>
    </div>
    <div class='column'>
      <router-link class="linkColor" to="/קטלוג/וילונות/וילונות-לסלון-בהתאמה-אישית">וילונות בהתאמה אישית</router-link>
    </div>
  </div>
</div>

<div class='some-page-wrapper'>
<div class='row'>
    <div class='column'>
        <router-link class="linkColor" to="/קטלוג/מוטות">מוטות</router-link>
    </div>
    <div class='column'>
        <router-link class="linkColor" to="/קטלוג/מפות">מפות</router-link>
    </div>
    <div class='column'>
        <router-link class="linkColor" to="/קטלוג/הדומים">הדומים</router-link>
    </div>
  </div>
</div>

<div class='some-page-wrapper'>
<div class='row'>
    <div class='column'>
        <router-link class="linkColor" to="/קטלוג/וילונות/וילונות-לבית-בזול">וילונות לבית בזול</router-link>  
    </div>
    <div class='column'>
        <router-link class="linkColor" to="/קטלוג/וילונות/וילונות-פלדים-על-מסילה">וילונות פלדים על מסילה</router-link>  
    </div>
    <div class='column'>
        <router-link class="linkColor" to="/קטלוג/וילונות">וילונות</router-link>  
    </div>
  </div>
</div>
    <div class="bottomFooter">
      <div> <router-link class="linkColor bold" to="/אודות">אודות</router-link> 
      |  <router-link class="linkColor bold" to="/תקנון">תקנון</router-link>  </div>
      <div class="pt-2">אימייל: allforhome.contact@gmail.com</div>
      <div class="pt-2">האתר פותח על ידי רועי מתוקי roymatoki7@gmail.com</div>
    </div>
    </div>
</div>
</template>

<script>
import TopNavbar from './components/TopNavbar.vue'

export default {
  name: 'app',
  components: {
     TopNavbar,
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

#app {
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
padding-top: 72px;}
html, body {
  font-family: 'Rubik', sans-serif;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
/* #nav a.router-link-exact-active {
  color: #42b983;
} */
#footer {
   width:100%;
   background: #eee;
   color: #0f0f0f;
   bottom: 0;
   padding-top: 2%;
   font-size: calc(0.6em + 0.5vw);
}
.bottomFooter {
  background-color: #e2e2e2e8;
  color: #666;
  padding: 1%;
  margin-top: 2%;
}
.linkColor {
  color: #0f0f0f;
}
.some-page-wrapper {
  display: flex;
  margin: 15px;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
.bold {
    font-weight: bold;
}
</style>
