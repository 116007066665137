import Vue from 'vue'
import VueRouter from 'vue-router'
import test3 from '../components/test3.vue'
import info from '../components/info.vue'
import Terms from '../components/terms.vue'
import CartItems from '../components/cartItems.vue'
import detailsForm from '../components/detailsForm.vue'
import Calculator from '../components/calculate.vue'
// import customeForm from '../components/customeForm.vue'
import productDetails from '../components/productDetails.vue'
import confirmation from '../components/confirmation.vue'
import bitPayment from '../components/bitPayment.vue'
import homePage from '../components/homePage.vue'
import errorPage from '../components/errorPage.vue'
import reviews from '../components/reviews.vue'

Vue.use(VueRouter)

const routes = [

  { 
    path: "/", 
    component: homePage,
    meta: {
      title: 'וילונות מעצבים חללים',
    }
  },
  {
    path: '/קטלוג',
    component: test3,
    meta: {
      title: 'קטלוג - וילונות מעצבים חללים',
    }
  },
  {
    path: '/קטלוג/:mainCategory/:subCategory',
    component: test3,
    name: 'catalog',
    props: true,
    meta: {
      title: 'וילונות מעצבים חללים',
    }
  },
  {
    path: '/קטלוג/:mainCategory',
    component: test3,
    name: 'accessories_catalog',
    props: true,
    meta: {
      title: 'וילונות מעצבים חללים',
    }
  },
  {
    path: '/קטלוג/:category/:name',
    component: productDetails,
    name: 'product',
    props: true,
    meta: {
      title: 'פרטי מוצר - וילונות מעצבים חללים ',
    }
  },
  {
    path: '/אודות',
    component: info,
    meta: {
      title: 'אודות - וילונות מעצבים חללים ',
    }
  },
  {
    path: '/תקנון',
    component: Terms,
    meta: {
      title: 'תקנון - וילונות מעצבים חללים ',
    }
  },
  {
    path: '/סל-קניות',
    component: CartItems,
    meta: {
      title: 'סל קניות - וילונות מעצבים חללים ',
    }
  },
  {
    path: '/מה-אמרו-עלינו',
    component: reviews,
    meta: {
      title: 'מה אמרו עלינו - וילונות מעצבים חללים ',
    }
  },
  {
    path: '/פרטים',
    component: detailsForm,
    meta: {
      title: 'פרטים - וילונות מעצבים חללים ',
      metaTags: [
        {
          name: 'robots',
          content: 'noindex'
        },
      ]
    }
  },
  {
    path: '/אישור-הזמנה',
    component: confirmation,
    meta: {
      title: 'אישור הזמנה - וילונות מעצבים חללים ',
      metaTags: [
        {
          name: 'robots',
          content: 'noindex'
        },
      ]
    }
  },
  {
    path: '/תשלום/ביט',
    component: bitPayment,
    meta: {
      title: 'תשלום - וילונות מעצבים חללים',
      metaTags: [
        {
          name: 'robots',
          content: 'noindex'
        },
      ]
    }
  },
  { 
    path: "*", 
    component: errorPage,
    meta: {
      title: 'העמוד לא נמצא - וילונות מעצבים חללים',
      metaTags: [
        {
          name: 'robots',
          content: 'noindex'
        },
      ]
    },
  },

  { 
    path: "/custome/calculator", 
    component: Calculator,
    meta: {
      title: 'וילונות מעצבים חללים',
      metaTags: [
        {
          name: 'robots',
          content: 'noindex'
        },
      ]
    }
  },

  // { 
  //   path: "/form/custome", 
  //   component: customeForm,
  //   meta: {
  //     title: 'וילונות מעצבים חללים',
  //     metaTags: [
  //       {
  //         name: 'robots',
  //         content: 'noindex'
  //       },
  //     ]
  //   }
  // },
]


const router = new VueRouter({
  mode: 'history',
  hash: false,
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router 
