<template>
<div id="form">
    <div class="container-fluid">
        <div class="center">

            <div class="card formCard">
            <div class="card-body">
                
                <div class="title"><b>
                    פרטי הזמנה
                </b></div>
            
            <form @submit.prevent="handleSubmit">
                <div class="form-group">
                    <label for="firstName" class="mylabel mt-4">שם פרטי *</label>
                    <input type="text" v-model="user.firstName" id="firstName" name="firstName" 
                    class="form-control myinput" :class="{ 'is-invalid': submitted && $v.user.firstName.$error,
                    'is-valid': submitted && !$v.user.firstName.$error }" @blur='checkIsValid($v.user.firstName, $event)'/>
                    <div v-if="submitted && !$v.user.firstName.required" class="invalid-feedback">יש למלא שם פרטי</div>
                    <div v-if="submitted && !$v.user.firstName.minLength" class="invalid-feedback">יש למלא שם פרטי תקין</div>
                </div>
                <div class="form-group">
                    <label for="lastName" class="mylabel">שם משפחה *</label>
                    <input type="text" v-model="user.lastName" id="lastName" name="lastName" 
                    class="form-control myinput" :class="{ 'is-invalid': submitted && $v.user.lastName.$error, 
                    'is-valid': submitted && !$v.user.lastName.$error}" @blur='checkIsValid($v.user.lastName, $event)'/>
                    <div v-if="submitted && !$v.user.lastName.required" class="invalid-feedback">יש למלא שם משפחה</div>
                    <div v-if="submitted && !$v.user.lastName.minLength" class="invalid-feedback">יש למלא שם משפחה תקין</div>
                </div>

                <div class="form-group">
                    <label for="city" class="mylabel">עיר מגורים *</label>
                    <input type="text" v-model="user.city" id="city" name="city" 
                    class="form-control myinput" :class="{ 'is-invalid': submitted && $v.user.city.$error, 
                    'is-valid': submitted && !$v.user.city.$error}" @blur='checkIsValid($v.user.city, $event)'/>
                    <div v-if="submitted && !$v.user.city.required" class="invalid-feedback">יש למלא עיר מגורים</div>
                </div>

                <div class="form-group">
                    <label for="street" class="mylabel">רחוב ומספר בית *</label>
                    <input type="text" v-model="user.street" id="street" name="street" 
                    class="form-control myinput" :class="{ 'is-invalid': submitted && $v.user.street.$error, 
                    'is-valid': submitted && !$v.user.street.$error}" @blur='checkIsValid($v.user.street, $event)'/>
                    <div v-if="submitted && !$v.user.street.required" class="invalid-feedback">יש למלא רחוב ומספר</div>
                </div>

                <div class="form-group">
                    <label for="email" class="mylabel">אימייל *</label>
                    <input type="email" v-model="user.email" id="email" name="email" 
                    class="form-control myinput ltr" :class="{ 'is-invalid': submitted && $v.user.email.$error, 
                    'is-valid': submitted && !$v.user.email.$error}" @blur='checkIsValid($v.user.email, $event)'/>
                    <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                        <span v-if="!$v.user.email.required">יש למלא אימייל</span>
                        <span v-if="!$v.user.email.email">אימייל לא תקין</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="phone" class="mylabel">מספר פלאפון *</label>
                    <input type="tel" v-model="user.phone" id="phone" name="phone" 
                    class="form-control myinput ltr" :class="{ 'is-invalid': submitted && $v.user.phone.$error, 
                    'is-valid': submitted && !$v.user.phone.$error }" @blur='checkIsValid($v.user.phone, $event)'/>
                    <div v-if="submitted && !$v.user.phone.required" class="invalid-feedback">יש למלא מספר פלאפון</div>
                    <div v-if="submitted && (!$v.user.phone.minLength || !$v.user.phone.maxLength)" class="invalid-feedback">יש למלא מספר פלאפון תקין</div>
                </div>

                <div class="form-group">
                    <label for="comments" class="mylabel">הערות להזמנה</label>
                    <textarea class="form-control myinput" v-model="user.comments" rows="2"></textarea>
                </div>

                <div class="form-group">
                <div class="input inline" :class="{invalid: $v.user.terms.$invalid}">
                    <input type="checkbox" id="terms" @change="$v.user.terms.$touch()" v-model="user.terms" :class="{ 'is-invalid': submitted && $v.user.terms.$error, 
                    'is-valid': submitted && !$v.user.terms.$error}" @blur='checkIsValid($v.user.terms, $event)'/>
                    <label for="terms" class="pr-1">אני מאשר את  <router-link to="/תקנון">התקנון</router-link></label>
                    <div v-if="submitted && $v.user.terms.$invalid" class="invalid-feedback">יש לאשר את התקנון</div>
                </div>
                </div>


                <div class="form-group">
                  <div><b> סכום הזמנה: {{ getTotal }} ש"ח</b></div>
                    <button class="btn btn-dark mt-4">השלם הזמנה</button> 

                    <div v-if="this.correctForm" class="mt-4">
                    <label><input class="ml-1" type="radio" name="optradio">
                    <a @click="bitPayment" class="blackTxt">לתשלום מאובטח בביט לחץ כאן <img src="../assets/bit.png" class="bitImg"></a>
                   </label>
                  </div>

                    <div v-if="this.correctForm" class="mt-3">
                      <label  class="blackTxt"><input class="ml-1" type="radio" name="optradio" 
                      >תשלום מאובטח דרך פייפאל או כרטיס אשראי:</label>
                       <PayPal v-if="this.correctForm"
                      locale="he_IL"
                      class="paypalButton"
                      :amount="getTotalString"
                      currency="ILS"
                      :button-style="myStyle"
                      :client="credentials"
                      :invoice-number="this.myUuid"
                      v-on:payment-completed="completed()"
                      v-on:payment-cancelled="cancelled()">
                    </PayPal>
                     <!-- env="sandbox" -->
                    </div>   
                     
                </div>
            </form>
        </div>
    </div>
    </div>
  </div><div v-if="this.showSpinner" class="loader"></div>
</div>
</template>

<script>
import { required, email, minLength, maxLength, sameAs} from "vuelidate/lib/validators";
import { mapActions, mapState } from 'vuex'
import PayPal from 'vue-paypal-checkout'

    export default {
        name: "app",
        components: {
          PayPal
        },
        data() {
            return {
              showSpinner: false,
               myStyle: {
                      label: 'paypal', 
                      shape: 'pill',
                      size: 'medium',
                      color: 'gold',
                      tagline: false,
                      fundingicons: true, 
                  },
               myUuid: this.randomString(8, '0123456789'),
               credentials: {
                sandbox: 'AWbmC62JMein_ew-i0GAygJofGscbOUPxqJ2ggHPOq4i66l_aZscNDcV_M0wzFMyMnPLzSI5dXHU299O',
                production: 'AfhdJBYsLk-DkrGMxAMc--25IhEbvquuVc5PegO1t5lKYBzsAV315KtgxkvnRjAytxxbMCWe9dFhuy9Z'
              },
                user: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    city: "",
                    street: "",
                    comments: "",
                    terms: false
                },
                submitted: false,
                correctForm: false,
            };
        },
        validations: {
            user: {
                firstName: { required,  minLength: minLength(2) },
                lastName: { required,  minLength: minLength(2) },
                email: { required, email },
                phone: { required, minLength: minLength(10), maxLength: maxLength(10) },
                city: { required},
                street: { required},
                terms: {
                  sameAs: sameAs( () => true )
                }
            }
        },
        methods: {
          ...mapActions(['submitOrder', 'changeOrderSuccess', 'submitBitRequest', 'changeTransactionID']
          ),  
            handleSubmit() {
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid || this.user.city === undefined) {
                    return;
                }
                
                this.showSpinner = true;

                setTimeout(() => {
                  this.correctForm = true;
                  this.showSpinner = false;
              }, 2000);
              
            },
            checkIsValid (val, event) {
                 if (val.$anyError) {
                   event.target.classList.add('form__input-shake')
                   setTimeout(() => {
                     event.target.classList.remove('form__input-shake')
                   }, 600)
                 }
            },
            scrollToTop() {
              window.scrollTo(0,0);
            },
            completed() {
              this.changeTransactionID(this.myUuid);
              this.submitOrder(this.user);
              this.changeOrderSuccess(true);
              // this.$router.push('/order/אישור-הזמנה').catch(err => {err});
              this.cleanLocalStorage();
            },
            cancelled() {
              this.changeOrderSuccess(false);
              this.$router.push('/אישור-הזמנה').catch(err => {err});
            },
            bitPayment() {
              this.showSpinner = true;
              this.changeTransactionID(this.myUuid);

              setTimeout(() => {
                this.submitBitRequest(this.user);
                this.cleanLocalStorage();
                this.showSpinner = false;
              }, 2000);
            },
            randomString(length, chars) {
                var result = '';
                for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
                return result;
            },
            cleanLocalStorage() {
              if(localStorage.getItem('firstName'))
                localStorage.removeItem('firstName')
              if(localStorage.getItem('lastName'))
                localStorage.removeItem('lastName')
              if(localStorage.getItem('city'))
                localStorage.removeItem('city')
              if(localStorage.getItem('street'))
                localStorage.removeItem('street')
              if(localStorage.getItem('email'))
                localStorage.removeItem('email')
              if(localStorage.getItem('phone'))
                localStorage.removeItem('phone')
              if(localStorage.getItem('comments'))
                localStorage.removeItem('comments')
            }
        },
        created() {
          this.scrollToTop();
          this.user.terms = false;
          if(localStorage.getItem('firstName'))
            this.user.firstName=localStorage.getItem('firstName')
          if(localStorage.getItem('lastName'))
            this.user.lastName=localStorage.getItem('lastName')
          if(localStorage.getItem('city'))
            this.user.city=localStorage.getItem('city')
          if(localStorage.getItem('street'))
            this.user.street=localStorage.getItem('street')
          if(localStorage.getItem('email'))
            this.user.email=localStorage.getItem('email')
          if(localStorage.getItem('phone'))
            this.user.phone=localStorage.getItem('phone')
          if(localStorage.getItem('comments'))
            this.user.comments=localStorage.getItem('comments')
        },
         computed: {
        ...mapState(['cart', 'shippingPrice', 'coupon']),
          getSubtotal() {
            return this.cart.reduce(
              (a, b) => a + b.price * b.quantity,
              0
            )
          },
          getTotal() {
            if(this.coupon !== 0) {
              return (
                (this.getSubtotal * (100 - this.coupon) / 100) + parseInt(this.shippingPrice) 
              )
            } else {
              return (
                this.getSubtotal + parseInt(this.shippingPrice) 
              )
            }
          },
          getTotalString() {
            if(this.coupon !== 0) {
              return (
                ((this.getSubtotal * (100 - this.coupon) / 100) + parseInt(this.shippingPrice)).toString() 
              )
            } else {
              return (
                (this.getSubtotal + parseInt(this.shippingPrice)).toString() 
              )
            }
          },
         },
        watch: {
          'user.firstName': function () {
              localStorage.setItem('firstName', this.user.firstName)
          },
          'user.lastName': function () {
              localStorage.setItem('lastName', this.user.lastName)
          },
          'user.city': function () {
              localStorage.setItem('city', this.user.city)
          },
          'user.street': function () {
              localStorage.setItem('street', this.user.street)
          },
          'user.email': function () {
              localStorage.setItem('email', this.user.email)
          },
          'user.phone': function () {
              localStorage.setItem('phone', this.user.phone)
          },
          'user.comments': function () {
              localStorage.setItem('comments', this.user.comments)
          },
        }
    };
</script>

<style scoped>
#form {
  background-color: #d89959ba;
  padding-top: 4%;
  padding-bottom: 5%;
}
.form__input {
  border: none;
  outline: none;
  border-bottom: 1px solid #eee;
  font-size: 1em;
  padding: 5px;
  display: block;
  margin: 10px 0 5px 0;
}

.form__error {
  color: red;
  font-size: 0.75em;
  padding-left: 10px;
}

.form__submit {
  background-color: #0033cc;
  display: inline-block;
  color: white;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 30px;
}

.form__input-shake {
  animation: shake 0.2s;
  animation-iteration-count: 3;
}

@keyframes shake {
  0% { transform: translateX(0px)  }
  25% { transform: translateX(2px) }
  50% { transform: translateX(0px)  }
  75% { transform: translateX(-2px) }
  100% { transform: translateX(0px)  }
}

* {
  box-sizing: border-box;
}

.center {
  margin: auto;
  padding: 10px;
}

@media only screen and (min-width: 500px) {
.center {
  margin: auto;
  padding: 10px;
  width: 75%;
}
}

@media only screen and (min-width: 800px) {
.center {
  margin: auto;
  padding: 10px;
  width: 50%;
}
}
.mylabel {
    display: inline-block;
    margin-bottom: 0.1rem;
    float: right;
    color: hsl(0deg 0% 0%);
    font-weight: 500;
    padding-right: 5px;
}
.formCard {
    border-radius: 15px;
}
.myinput {
    border-radius: 20px;
}
.title {
  height: 50px;
  border-bottom: 1px solid #E1E8EE;
  padding: 0px;
  color: black;
  font-size: 22px;
  font-weight: 400;
}
.ltr {
    text-align: left; 
    direction: ltr;
}
.paypalButton{
  margin-top: 2%;
}
.invalid-feedback1 {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.submitbtn{
  border-radius: 8px;
  background-color: #0b0b0be0;
  border: none;
  color: white;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
}
.bitImg {
  height: 50px;
}
.blackTxt {
  color: black;
}
.loader {
  color: #1f201f;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; 
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  30% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  58% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
